import './Changelog.scss'
import { Component } from '@tooooools/ui'

import classnames from 'classnames'

import changelog from '/data/changelog'

import { Modal } from '@tooooools/ui/components'

export default class ChangelogModalComponent extends Component {
  template (props) {
    return (
      <Modal
        class='changelog-modal'
        title='Dernières mises à jour'
        event-close={props['event-close']}
      >
        {
          Object.entries(changelog).map(([version, { features, improvements, fixes, message }], index) => (
            <div
              class={classnames('changelog-modal__version prose', {
                'is-new': index < Object.keys(changelog).indexOf(props.previous)
              })}
            >
              <h2>{version}</h2>

              {message && <p>{message}</p>}

              {features && [
                <h3>🎉&nbsp;Nouveautés</h3>,
                <ul>
                  {features.map(feature => <li innerHTML={feature} />)}
                </ul>
              ]}

              {improvements && [
                <h3>✨&nbsp;Améliorations</h3>,
                <ul>
                  {improvements.map(improvement => <li innerHTML={improvement} />)}
                </ul>
              ]}

              {fixes && [
                <h3>🐛&nbsp;Corrections de bugs</h3>,
                <ul>
                  {fixes.map(fix => <li innerHTML={fix} />)}
                </ul>
              ]}
            </div>
          ))
        }
      </Modal>
    )
  }

  afterMount () {
    // Do not render if no changelog
    if (!Object.values(changelog).length) this.destroy()
  }
}
