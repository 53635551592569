export const group = 'objet'
export const value = 'pan'
export const label = 'balayage'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)
  const easing = 'expoInOut'

  return {
    ['#' + svgElement.id]: {
      opacity: { from: 0, to: 1, delay, duration, easing },
      translateX: { from: -50, to: 0, delay, duration, easing }
    }
  }
}
