export default ([width, height], [containerWidth, containerHeight]) => {
  const ratio = height / width
  const containerRatio = containerHeight / containerWidth

  return containerRatio > ratio
    ? {
        width: containerHeight / ratio,
        height: containerHeight,
        fit: 'width',
        scale: containerHeight / height
      }
    : {
        width: containerWidth,
        height: containerWidth * ratio,
        fit: 'height',
        scale: containerWidth / width
      }
}
