import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

export const os = browser.getOS()
export const supported = {
  Chrome: '>=87',
  Firefox: '>=78',
  Safari: '>=14',
  Edge: '>=88'
}

export const name = browser.getBrowserName()
export const version = browser.getBrowserVersion()
export const isSupported = browser.satisfies(supported)
export const isMobile = browser.is('mobile')
