/* global Blob, FileReader */
import FileSaver from 'file-saver'

import Store from '/data/store'

export function save (filename, { blob, url, json } = {}) {
  if (json) {
    const string = JSON.stringify(json, null, Store.env.debug.get() ? 2 : 0)
    blob = new Blob([string], { mimetype: 'application/json' })
  }

  return FileSaver.saveAs(blob ?? url, filename)
}

export function load (file, {
  method = 'readAsText'
} = {}) {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error('File is undefined'))

    const fReader = new FileReader()
    fReader.onload = () => resolve(fReader.result)
    fReader.onerror = reject
    fReader[method](file)
  })
}

export function browse ({
  accept = '*',
  multiple = false
} = {}) {
  return new Promise(resolve => {
    const stopEvents = ['focus']
    const stop = () => {
      for (const event of stopEvents) window.removeEventListener(event, stop)
      window.setTimeout(resolve, 300)
    }

    const input = document.createElement('input')
    if (multiple) input.setAttribute('multiple', 'multiple')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', accept)
    input.style.display = 'none'
    input.addEventListener('change', e => {
      window.removeEventListener('focus', stop)
      resolve(input.files)
      input.remove()
    })

    input.click()
    window.requestAnimationFrame(() => {
      for (const event of stopEvents) window.addEventListener(event, stop, { once: true })
    })
  })
}
