export default (element, scale = 1) => {
  const rect = element.getBoundingClientRect()

  const scaled = {}
  for (const props in rect) {
    scaled[props] = rect[props] / scale
  }

  return scaled
}
