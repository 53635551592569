import './App.scss'
import { Component } from '@tooooools/ui'
import { derived, not } from '@tooooools/ui/state'

import px from 'to-px'
import { debounce } from 'throttle-debounce'

import Store from '/data/store'
import Data from '/data/static'
import * as Icons from '/data/icons'

import * as Actions from '/controllers/Actions'
import * as Animation from '/controllers/Animation'
import * as Animations from '/controllers/Animations'
import * as Block from '/controllers/Block'
import * as Document from '/controllers/Document'
import * as Formats from '/controllers/Formats'
import * as Page from '/controllers/Page'
import * as Tour from '/controllers/Tour'

import {
  Button,
  Input,
  Range,
  Select,
  Toolbar
} from '@tooooools/ui/components'
import Artboard from '/components/Artboard'
import Changelog from '/components/Modal/Changelog'
import Context from '/components/Context'
import Timeline from '/components/Timeline'

import capitalize from '/utils/string-capitalize'

export default class AppComponent extends Component {
  beforeRender () {
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.state = {
      isEmpty: derived(Store.document.pages, pages => !pages.size),
      isAnimationContext: Context.matchSignal('timeline')
    }
  }

  template (props, state) {
    return (
      <main
        class='app'
        store-data-context={Store.app.context}
        store-class-is-saving={Store.app.saving}
        store-class-is-xray={Store.app.xray}
        event-mousemove={this.handleMouseMove}
      >
        <section class='toolbars prevent-context-change' data-position='top'>
          <Context autoHide name='timeline'>
            <Toolbar>
              <Button
                icon={Icons.duration}
                title='Durée totale de l’animation'
                store-label={derived(Animation.duration, d => `${d}&thinsp;s`)}
              />
            </Toolbar>
          </Context>

          <Context autoHide name='timeline'>
            <Toolbar>
              <Toolbar compact>
                <Button
                  icon={Icons.minus}
                  title='Diminuer la durée de la séquence'
                  store-disabled={derived(Page.state('duration'), d => d <= 1)}
                  event-click={e => Page.exec('updateDuration', e.shiftKey ? -10 : -1)(e)}
                />
                <Input
                  title='Régler la durée de la séquence'
                  type='number'
                  after='s'
                  size='auto'
                  autoSelectAll
                  store-value={Page.state('duration')}
                  min={1}
                  event-input={(e, t) => Page.exec('setDuration', t.state.value.get())(e)}
                />
                <Button
                  icon={Icons.plus}
                  title='Augmenter la durée de la séquence'
                  event-click={e => Page.exec('updateDuration', e.shiftKey ? 10 : 1)(e)}
                />
              </Toolbar>
              <Button
                icon={Icons.edit}
                title='Éditer le gabarit'
                event-click={() => Store.app.context.set('page')}
              />
              <Toolbar compact>
                <Button
                  icon={Icons.delete}
                  title='Supprimer le gabarit'
                  event-click={e => Actions.confirm(Actions.deletePage, {
                    message: <p>Supprimer le gabarit ? Cette action ne peut être annulée.</p>,
                    confirm: {
                      icon: Icons.delete,
                      label: 'supprimer'
                    }
                  })}
                />
                <Button
                  icon={Icons.clone}
                  title='Dupliquer le gabarit'
                  event-click={() => Actions.clonePage()}
                />
              </Toolbar>
            </Toolbar>
          </Context>

          <Context autoHide name='page'>
            <Toolbar>
              <Toolbar store-hidden={state.isEmpty}>
                <Button
                  icon={Icons.template}
                  title='Changer de gabarit'
                  event-click={Page.exec('setTemplate', Actions.selectTemplate)}
                />
              </Toolbar>

              <Toolbar compact>
                <Range
                  icon={Icons.fontSize}
                  store-value={Page.state('fontScale')}
                  title='Ajuster l’échelle du document'
                  store-label={Store.env.debug.get() ? Page.state('fontScale') : null}
                  min={0.25}
                  max={2}
                  step={0.01}
                  event-input={(e, t) => Page.exec('setFontScale', t.state.value.get())(e)}
                />
                <Button
                  icon={Icons.restore}
                  title='Remettre à zéro l’échelle du document'
                  store-hidden={derived(Page.state('fontScale'), s => s === 1)}
                  event-click={Page.exec('setFontScale', 1)}
                />
              </Toolbar>
            </Toolbar>
          </Context>

          {
            Object.entries({
              front: { contrast: true, addText: true, addButton: true, addImage: true },
              back: { contrast: false, addText: false, addButton: false, addImage: false },
              background: { contrast: true, addText: true, addButton: true, addImage: true }
            }).map(([part, features]) => {
              const isImage = derived(Page.state(part + 'Texture'), tex => tex?.type === 'image')

              return (
                <Context autoHide name={`part:${part}`}>
                  <Toolbar>
                    {features.contrast && (
                      <Button
                        icon={Icons.contrast}
                        title='Inverser le contraste du texte sur l’image'
                        store-hidden={not(isImage)}
                        event-click={Page.exec('toggleTextureContrast', part)}
                      />
                    )}

                    <Toolbar
                      compact
                      store-hidden={not(Page.state('has' + capitalize(part)))}
                    >
                      <Button
                        icon={Icons.image}
                        title='Appliquer une image de texture'
                        store-active={isImage}
                        event-click={Page.exec('setTexture', part, Actions.selectTexture)}
                      />

                      {Data.colors.map(color => (
                        <Button
                          icon={Icons.color(color.value)}
                          title={capitalize(color.label)}
                          store-active={derived(Page.state(part + 'Texture'), tex => tex?.props?.value === color.value)}
                          event-click={Page.exec('setTexture', part, color)}
                        />
                      ))}
                    </Toolbar>

                    <Toolbar compact>
                      {features.addText && (
                        <Button
                          icon={Icons.addText}
                          title='Ajouter un texte flottant'
                          store-disabled={not(Page.state(`has${capitalize(part)}TextTemplate`))}
                          event-click={Page.exec('insert', 'text', part)}
                        />
                      )}

                      {features.addButton && (
                        <Button
                          icon={Icons.addButton}
                          title='Ajouter un call to action'
                          store-disabled={not(Page.state(`has${capitalize(part)}ButtonTemplate`))}
                          event-click={Page.exec('insert', 'button', part)}
                        />
                      )}

                      {features.addImage && (
                        <Button
                          icon={Icons.addImage}
                          title='Ajouter un pictogramme ou une image flottante'
                          store-disabled={not(Page.state(`has${capitalize(part)}ImageTemplate`))}
                          event-click={Page.exec('insert', 'image', part)}
                        />
                      )}
                    </Toolbar>
                  </Toolbar>
                </Context>
              )
            })
          }

          <Context autoHide name='object:*'>
            <Toolbar compact>
              {/* TODO <Button icon={Icons.clone} /> */}
              <Button
                icon={Icons.delete}
                event-click={e => Actions.confirm(() => Block.exec('delete')(e), {
                  message: <p>Supprimer l’objet ? Cette action ne peut être annulée.</p>,
                  confirm: {
                    icon: Icons.delete,
                    label: 'supprimer'
                  }
                })}
              />
            </Toolbar>
          </Context>

          <Context autoHide name='object:text'>
            <Toolbar>
              <Button
                icon={Icons.edit}
                title='Éditer l’objet texte sélectionné. Double-cliquer un objet texte permet également de l’éditer'
                store-active={Block.state('editable')}
                event-click={Block.exec('toggleEditText')}
              />

              <Toolbar store-hidden={not(Block.state('editable'))}>
                <Toolbar compact>
                  <Button
                    icon={Icons.minus}
                    title='Maintenir la touche majuscule enfoncée pour décrémenter de 10 en 10'
                    event-click={e => Block.exec('updateFontSize', e.shiftKey ? -10 : -1)(e)}
                  />
                  <Input
                    type='number'
                    after='px'
                    size='auto'
                    autoSelectAll
                    store-value={Block.state('fontSize')}
                    // BUG cannot input multiple time because Block.exec send focus back to the current Block
                    event-input={(e, t) => Block.exec('setFontSize', t.state.value.get())(e)}
                  />
                  <Button
                    icon={Icons.plus}
                    title='Maintenir la touche majuscule enfoncée pour incrémenter de 10 en 10'
                    event-click={e => Block.exec('updateFontSize', e.shiftKey ? 10 : 1)(e)}
                  />
                </Toolbar>
                <Toolbar compact>
                  <Button
                    icon={Icons.bold}
                    store-active={Block.state('isBold')}
                    event-click={Block.exec('toggleBold')}
                  />
                  <Button
                    icon={Icons.italic}
                    store-active={Block.state('isItalic')}
                    event-click={Block.exec('toggleItalic')}
                  />
                  {/* TODO[html-to-svg] underline support */}
                </Toolbar>

                <Toolbar compact>
                  <Button
                    icon={Icons.alignLeft}
                    store-active={Block.state('textAlign', 'left')}
                    event-click={Block.exec('setTextAlign', 'left')}
                  />
                  <Button
                    icon={Icons.alignCenter}
                    store-active={Block.state('textAlign', 'center')}
                    event-click={Block.exec('setTextAlign', 'center')}
                  />
                  <Button
                    icon={Icons.alignRight}
                    store-active={Block.state('textAlign', 'right')}
                    event-click={Block.exec('setTextAlign', 'right')}
                  />
                </Toolbar>
              </Toolbar>
            </Toolbar>
          </Context>

          <Context autoHide name='object:logo'>
            <Toolbar>
              <Button
                icon={Icons.image}
                title='Changer le logo sélectionné. Double-cliquer un objet logo permet également de le changer'
                event-click={Block.exec('setSources', Actions.selectLogo)}
              />
            </Toolbar>
          </Context>

          <Context autoHide name='object:picto'>
            <Toolbar>
              <Button
                icon={Icons.image}
                title='Changer le pictogramme sélectionné. Double-cliquer un objet picto permet également de le changer'
                event-click={Block.exec('setSources', Actions.selectPicto)}
              />
            </Toolbar>
          </Context>

          {Data.animation?.blocks && (
            <Context autoHide name='object:*'>
              <Toolbar
                compact
                store-hidden={derived(Block.state('type'), type => !type)}
              >
                <Select
                  icon={Icons.animate}
                  title='Choisir une animation pour l’objet sélectionné'
                  compare={Animations.compareValue}
                  store-value={Block.state('animation')}
                  store-label={derived(Block.state('animation'), a => (Animations[a] ?? Animations.none).label)}
                  dropdown={derived(Block.state('animation'), a => a ? '' : Icons.dropdown)}
                  store-options={derived(Block.state('type'), type => {
                    return [
                      Animations.none,
                      Select.separator,
                      ...(Data.animation.blocks[type] ?? []).map(value => Animations[value])
                    ]
                  })}
                  event-change={(e, select) => Block.exec('setAnimation', select.state.value.get())(e)}
                />

                <Range
                  dual
                  icon={Icons.duration}
                  title='Modifier le timing de l’animation pour l’objet sélectionné'
                  step={0.01}
                  min={0}
                  max={1}
                  debounce={300}
                  store-value={Block.state('animationTiming')}
                  store-hidden={Block.state('animation', null)}
                  event-input={(e, t) => Block.exec('setAnimationTiming', t.state.value.get())(e)}
                />
              </Toolbar>
            </Context>
          )}

          <Toolbar class='toolbar--artboard'>
            <Toolbar store-hidden={Context.matchSignal('timeline')}>
              <Button
                icon={Icons.xray}
                store-hidden={derived([
                  Page.state('needsXray'),
                  Store.app.xray
                ], () => !(Page.state('needsXray').get() || Store.app.xray.get()))}
                store-title={derived(Store.app.xray, v => `${v ? 'Désactiver' : 'Activer'} le mode rayons X (permet de cliquer à travers les objets)`)}
                store-active={Store.app.xray}
                event-click={e => Store.app.xray.update(xray => {
                  if (xray) return false
                  Store.app.context.set('part:background')
                  Store.app.block.set(null)
                  return true
                })}
              />
            </Toolbar>

            <Toolbar
              compact
              store-hidden={derived(Store.document.pages, pages => pages.size <= 1)}
            >
              <Button
                icon={Icons.moveLeft}
                store-disabled={not(Page.state('index'))}
                title='Aller au gabarit précédent'
                event-click={() => Actions.selectPage(-1)}
              />
              <Button
                icon={Icons.moveRight}
                store-disabled={derived([Store.document.pages, Page.state('index')], () => Page.state('index').get() >= Store.document.pages.current.size - 1)}
                title='Aller au gabarit suivant'
                event-click={() => Actions.selectPage(+1)}
              />
            </Toolbar>

            <Button
              store-icon={derived(state.isAnimationContext, m => m ? Icons.close : Icons.grid)}
              store-disabled={derived([state.isEmpty, state.isAnimationContext], () => state.isEmpty.current && !state.isAnimationContext.current)}
              store-active={state.isAnimationContext}
              event-click={() => Store.app.context.update(context => Context.match('timeline', context) ? 'page' : 'timeline')}
            />
          </Toolbar>
        </section>

        <Context name='page'>
          <section class='app__content'>
            <Artboard
              ref={this.ref('artboard')}
              store-hidden={state.isAnimationContext}
            />
            <Context autoHide name='timeline'>
              <Timeline />
            </Context>
          </section>
        </Context>

        <section class='toolbars prevent-context-change' data-position='bottom'>
          <Toolbar class='toolbar--document'>
            <Toolbar compact>
              <Button
                icon={Icons.new}
                title='Créer un nouveau document'
                event-click={e => Document.data.isEmpty()
                  ? Document.data.reset()
                  : Actions.confirm(Document.data.reset, {
                    title: 'Nouveau document',
                    message: <p>Attention, la création d’un nouveau document entrainera la perte de toute modification non enregistrée.</p>,
                    confirm: { label: 'confirmer' }
                  })}
              />
              <Button
                icon={Icons.load}
                title='Charger un document précédemment enregistré'
                event-click={Document.file.load}
              />
              {/* TODO[restore] move icon in empty page button
                <Button
                  icon={Icons.restore}
                  title='Restaurer la dernière session de ce navigateur'
                  label='restaurer'
                /> */
              }
            </Toolbar>

            <Toolbar store-disabled={state.isEmpty}>
              <Toolbar compact>
                <Select
                  icon={Icons.format}
                  title='Changer le format du document'
                  store-label={Formats.selectLabel}
                  // Use a proxy as a value so that this component won’t
                  // automatically changes the signal value (store value is
                  // changed via event-change after some filtering)
                  store-value={Formats.selectValue}
                  compare={Formats.selectCompare}
                  store-options={Formats.selectOptions}
                  event-change={Formats.handleChange}
                />
                <Button
                  icon={Icons.updateFormat}
                  store-hidden={derived(Store.document.format, format => format.group !== 'format personnalisé')}
                  store-title={derived(Store.document.format, format => `Modifier le format ${format.label ?? 'personnalisé'}`)}
                  event-click={Formats.updateCurrent}
                />
              </Toolbar>

              <Button
                store-icon={derived(Document.isAnimated, a => a ? Icons.play : Icons.previewStatic)}
                title='Prévisualiser le document'
                label='prévisualiser'
                event-click={Actions.preview}
              />
            </Toolbar>
          </Toolbar>

          <Toolbar class='toolbar--export' store-disabled={state.isEmpty}>
            <Button
              icon={Icons.save}
              title='Enregistrer le document'
              event-click={Document.file.save}
            />
            <Button
              icon={Icons.export}
              title='Exporter le document'
              label='exporter'
              event-click={Actions.exportDocument}
            />
          </Toolbar>
        </section>

        <footer class='app__status-bar'>
          <fieldset store-data-title={derived(Store.app.viewportScale, scale => `Document affiché à ${(scale * 100).toFixed(0)}%`)}>
            {Store.env.debug.get() && <span store-text={Store.raf.frameCount} />}
            <span store-text={derived(Store.app.viewportScale, scale => `(${(scale * 100).toFixed(0)}%)`)} />
            <span store-innerHTML={derived(Store.document.format, format => `${format.width}&thinsp;×&thinsp;${format.height}&thinsp;${format.unit}`)} />
          </fieldset>
          <fieldset>
            <div class='app__help' ref={this.ref('help')} />
            <a
              class='app__version'
              store-text={Store.env.version}
              event-click={() => this.render(<Changelog />)}
            />
            <Button
              class='btn--help'
              title='Lancer la visite guidée'
              icon={Icons.help}
              event-click={Tour.start}
            />
          </fieldset>
        </footer>
      </main>
    )
  }

  afterMount () {
    Store.document.format.subscribe(this.handleResize)
    window.addEventListener('resize', debounce(100, this.handleResize))
    this.handleResize()
  }

  handleResize () {
    const { width, height, unit, baseFontScale } = Store.document.format.get()

    // Scale to fit
    const artboard = this.refs.artboard.getBoundingClientRect()
    const scale = Math.min(
      1,
      Math.min(
        ((artboard.width) - 160) / px(width + unit),
        (artboard.height) / px(height + unit)
      )
    )
    Store.app.viewportScale.set(scale)

    // Set CSS vars to use in various components. This may not be ideal as it
    // relies on global pattern, but is good enough for now
    this.base.style.setProperty('--page-width', width + unit)
    this.base.style.setProperty('--page-height', height + unit)
    this.base.style.setProperty('--page-ratio', width / height)
    this.base.style.setProperty('--page-base-font-scale', baseFontScale ?? 1)
    this.base.style.setProperty('--page-viewport-scale', scale)
  }

  handleMouseMove (e) {
    // Avoid flashing titles when dragging
    if (e.buttons > 0) return

    // Find the first [data-]title available in the ancestors
    window.requestAnimationFrame(() => {
      let title
      let el = e.toElement
      while (el && el !== this.base && !title) {
        title = el.dataset.title ?? el.title
        el = el.parentNode
      }

      this.refs.help.innerText = title ?? ''
    })
  }
}
