export * as fade from './fade'
export * as fall from './fall'
export * as pan from './pan'
export * as pop from './pop'
export * as rise from './rise'
export * as typewriter from './typewriter'
export * as shift from './shift'
export * as pulse from './pulse'
export * as rotate from './rotate'

export const none = { label: 'aucune animation', value: null }

// Used for <Select>.compare prop
export const compareValue = (a, b) => {
  if (a === null && b === none) return true
  if (b === none && a === null) return true
  return a === b
}
