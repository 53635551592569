import 'driver.js/dist/driver.css'
import { driver } from 'driver.js'

import Store from '/data/store'

import * as Actions from '/controllers/Actions'
import * as Document from '/controllers/Document'

import noop from '/utils/noop'

const disabledElements = []

const DRIVER = driver({
  popoverClass: 'tour prose',
  animate: true,
  allowClose: false,
  stageRadius: 10,
  popoverOffset: 10,
  side: 'top',
  overlayOpacity: 0.5,
  stagePadding: 10
})

const STEPS = [
  /**
  {
    element?: Promise|function|string|HTMLElement,
    title?: string,
    description?: string[]|string,
    before?: Promise|function,
    waitFor?: Promise,
    after?: Promise|function
  }
  **/
  {
    title: 'Visite guidée',
    description: [
      '<b>Bienvenue dans le générateur AG2R LA MONDIALE !</b>',
      'Cet outil vous permet de concevoir et d’exporter des éléments de communication pour les réseaux sociaux, ainsi que pour différents formats d’impression et de bureautique.',
      'Si vous le souhaitez, cette courte visite guidée vous permettra de découvrir les fonctionnalités principales du générateur.'
    ]
  },

  {
    element: '.artboard .empty',
    align: 'center',
    description: [
      'C’est ici que vous allez pouvoir éditer votre document à partir d’un gabarit pré-conçu.',
      'Cliquez sur <mark>ajouter un gabarit</mark> pour commencer.'
    ],
    waitFor: step => new Promise(resolve => {
      const el = document.querySelector('.artboard .empty')
      el.addEventListener('click', resolve)
    })
  },

  {
    element: '.select-modal--templates',
    align: 'start',
    description: 'Sélectionnez un gabarit. Pour cette visite guidée, seul le premier gabarit peut être sélectionné.',
    before: () => {
      disable('.select-modal--templates .thumbnail:not(:first-child)')
      disable('.select-modal--templates button')
    },
    waitFor: async () => await new Promise(resolve => Store.document.pages.subscribeOnce(resolve))
  },

  delay(200),

  {
    element: '.artboard .page.is-active',
    description: 'Éditez la page directement en manipulant les éléments qui la composent.',
    before: () => disable('.page button')
  },

  {
    element: '.toolbars[data-position="top"]',
    side: 'bottom',
    align: 'start',
    description: [
      'Cet espace vous permet de contrôler les différentes options de votre document.',
      'La barre d’outils s’adapte aux éléments que vous sélectionnez dans la page.'
    ],
    before: () => {
      // Ensure user cannot click on the buttons
      disable('.toolbars button, .toolbars input')

      // Force edit toolbar visibility
      for (const el of document.querySelectorAll('.toolbars[data-position="top"] [data-context] > .is-hidden')) {
        el.classList.remove('is-hidden')
      }

      let index = 0
      const contexts = ['page', 'part:front', 'object:text', 'object:logo']

      Store.app.context.set(contexts[0])
      window.__tourTimer = window.setInterval(() => {
        Store.app.context.set(contexts[index])
        index = (index + 1) % contexts.length
      }, 600)
    },

    after: () => {
      window.clearInterval(window.__tourTimer)
      Store.app.context.set('page')
    }
  },

  {
    element: '.toolbars[data-position="top"]',
    side: 'bottom',
    align: 'end',
    description: 'Basculez entre différentes vues du document en activant les boutons ci-dessus, afin de faciliter le déplacement d’éléments en arrière-plan, ou d’activer une vue en grille lors du montage d’une séquence vidéo.'
  },

  {
    element: '.artboard .empty',
    side: 'left',
    align: 'center',
    description: [
      'Ajoutez d’autres pages à votre document pour concevoir des séquences animées, que vous pourrez ensuite exporter en GIF animé ou au format vidéo.'
    ]
  },

  {
    element: '.toolbar--export',
    side: 'top',
    description: [
      'Une fois votre document terminé, enregistrer un fichier source au format <code>.alm</code> afin de pouvoir l’éditer plus tard, et exportez-le dans différents formats animés et statiques.',
      '<b>Astuce :</b> votre session de travail est automatiquement enregistrée dans le navigateur, et sera restaurée à votre prochaine connexion.'
    ],
    before: () => disable('.toolbar--export button')
  },

  {
    element: '.app__status-bar',
    side: 'top',
    align: 'end',
    description: [
      'Besoin d’aide ? Lorsque vous survolez un élement de l’interface, un texte d’aide apparaît en bas à droite.',
      'Vous pouvez également relancer cette visite en cliquant sur le bouton <mark>?</mark>'
    ],
    before: () => disable('.app__status-bar .btn--help')
  }
]

function delay (ms) {
  return {
    isDelay: true,
    waitFor: () => new Promise(resolve => window.setTimeout(resolve, ms))
  }
}

function disable (CSSSelector) {
  for (const el of document.querySelectorAll(CSSSelector)) {
    disabledElements.push(el)
    if (['BUTTON', 'INPUT'].includes(el.tagName)) el.setAttribute('disabled', true)
    else el.classList.add('is-disabled')
  }
}

async function resolve (index) {
  const step = STEPS[index]
  return step && {
    before: step.before ?? noop,
    after: step.after ?? noop,
    waitFor: step.waitFor,
    element: (typeof step.element === 'function')
      ? await step.element()
      : step.element,
    popover: {
      title: step.title,
      description: Array.isArray(step.description)
        ? step.description.map(v => `<p>${v}</p>`).join('')
        : step.description,
      showProgress: true,
      progressText: `${index + 1}\u2009/\u2009${STEPS.length}`,
      nextBtnText: (() => {
        if (index === 0) return 'commencer'
        if (index === STEPS.length - 1) return 'terminer'
        else return 'suivant'
      })(),
      showButtons: [
        ...(index < STEPS.length && !step.waitFor ? ['next'] : []),
        ...(index === 0 ? ['close'] : [])
      ],
      side: step.side,
      align: step.align,
      onNextClick: async () => {
        await (step.after ?? noop)(step)
        await open(index + 1)
      },
      onCloseClick: end
    }
  }
}

export async function start ({ force = false, index = 0 } = {}) {
  if (!force && !Document.data.isEmpty() && !(await Actions.confirm(() => true, {
    title: 'Confirmer le lancement de la visite guidée',
    message: 'Attention, le lancement de la visite guidée entrainera la perte de toute modification non enregistrée.',
    confirm: {
      label: 'lancer la visite'
    }
  }))) return

  Document.data.reset()
  open(index)
}

export async function open (index = 0) {
  const step = await resolve(index)
  if (!step) return end()

  // Handle delay() step
  if (step.isDelay) {
    await step.waitFor(step)
    return open(index + 1)
  }

  await step.before(step)
  DRIVER.highlight(step)

  if (step.waitFor) {
    await step.waitFor(step)
    await step.after(step)
    await open(index + 1)
  }
}

export async function end () {
  console.log('end')
  DRIVER.destroy()

  for (const el of disabledElements) {
    if (!el) continue
    el.classList.remove('is-disabled')
    el.removeAttribute('disabled')
  }

  disabledElements.length = 0
}
