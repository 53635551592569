import Store from '/data/store'
import { raf, fpsLimiter } from '@internet/raf'

let running = Store.raf.running.get()

const update = () => Store.raf.frameCount.update(fc => ++fc)
const tick = fpsLimiter(Store.raf.fps.current, update)

Store.raf.running.subscribe(state => {
  if (state === running) return

  state ? start() : stop()
  running = state
})

export function start () {
  raf.add(tick)
  Store.raf.running.set(true)
}

export function stop () {
  raf.remove(tick)
  Store.raf.running.set(false)
}
