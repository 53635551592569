/**
 * Singleton defining all possible configurations, and exporting only the one
 * matching the current location.pathname

 * IMPORTANT: All data below are deep-frozen, as they should always be immutable
 * Mutable datas are handled by data/store
 */

import Store from '/data/store'
import deepFreeze from '/utils/object-deep-freeze'

export const Routes = new Map()

const Formats = {
  debug: import.meta.env.MODE === 'test'
    ? [
        { group: 'debug', label: 'template-preview', width: 1200, height: 600, unit: 'px', baseFontScale: 1 },
        { group: 'debug', label: 'square', width: 512, height: 512, unit: 'px', baseFontScale: 1 },
        { group: 'debug', label: 'landscape', width: 1024, height: 512, unit: 'px', baseFontScale: 1 },
        { group: 'debug', label: 'portrait', width: 512, height: 1024, unit: 'px', baseFontScale: 1 }
      ]
    : [],
  facebook: [
    { group: 'facebook', label: 'image partagée', width: 1200, height: 630, unit: 'px', baseFontScale: 1 },
    { group: 'facebook', label: 'image partagée carrée', width: 450, height: 450, unit: 'px', baseFontScale: 0.7 },
    { group: 'facebook', label: 'image de couverture', width: 851, height: 351, unit: 'px', baseFontScale: 0.6 },
    { group: 'facebook', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  x: [
    { group: 'x', label: 'image partagée', width: 1024, height: 512, unit: 'px', baseFontScale: 0.9 },
    { group: 'x', label: 'image de couverture', width: 1500, height: 500, unit: 'px', baseFontScale: 1 },
    { group: 'x', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  linkedin: [
    { group: 'linkedin', label: 'nouvelle de l’entreprise', width: 1200, height: 628, unit: 'px', baseFontScale: 1 },
    { group: 'linkedin', label: 'image de couverture', width: 1584, height: 396, unit: 'px', baseFontScale: 0.8 },
    { group: 'linkedin', label: 'couverture page vitrine', width: 1264, height: 220, unit: 'px', baseFontScale: 0.5 },
    { group: 'linkedin', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  print: [
    { group: 'impression', label: 'A3 paysage', width: 420, height: 297, unit: 'mm', baseFontScale: 1.3 },
    { group: 'impression', label: 'A3 portrait', width: 297, height: 420, unit: 'mm', baseFontScale: 1.3 },
    { group: 'impression', label: 'A4 paysage', width: 297, height: 210, unit: 'mm', baseFontScale: 0.9 },
    { group: 'impression', label: 'A4 portrait', width: 210, height: 297, unit: 'mm', baseFontScale: 0.9 }
  ]
}

/**
 * ALM
 */

Routes.set('/alm', deepFreeze({
  portal: {
    logo: '/logos/entite/alm_2c.svg',
    images: [
      '/portal/alm-1.jpg',
      '/portal/alm-2.jpg'
    ]
  },

  api: {
    // arnaudjuracek/svg-to-pdf does not handle clip-path
    pdfMode: 'rasterize'
  },

  defaults: {
    logo: data => data.logos[0],
    front: data => data.colors[0],
    back: data => data.colors[1],
    background: data => data.colors[8]
  },

  uploads: {
    formats: true,
    templates: false,
    logos: false,
    pictos: {
      multiple: true,
      accept: 'image/png, image/jpeg, image/svg+xml'
    },
    textures: {
      multiple: true,
      accept: 'image/png, image/jpeg'
    }
  },

  animation: {
    easing: 'expoInOut',
    duration: '2s',
    blocks: {
      image: ['fade', 'fall', 'pan', 'pop', 'rise', 'pulse'],
      text: ['fade', 'fall', 'pan', 'pop', 'rise', 'typewriter', 'shift', 'pulse']
    }
  },

  templates: [
    { label: 'gabarit simple', src: '/templates/alm/default/index.html', preview: { src: '/templates/alm/default/preview.png', ratio: 1200 / 600 } },
    { label: 'gabarit sans logo', src: '/templates/alm/default-no-logo/index.html', preview: { src: '/templates/alm/default-no-logo/preview.png', ratio: 1200 / 600 } },
    { label: 'gabarit double', src: '/templates/alm/double/index.html', preview: { src: '/templates/alm/double/preview.png', ratio: 1200 / 600 } },
    { label: 'cartouche', src: '/templates/alm/cartouche/index.html', preview: { src: '/templates/alm/cartouche/preview.png', ratio: 1200 / 600 } },
    { label: 'logo', src: '/templates/alm/logo/index.html', preview: { src: '/templates/alm/logo/preview.png', ratio: 1200 / 600 } },
    { label: 'gabarit vide', src: '/templates/alm/empty/index.html', preview: { src: '/templates/alm/empty/preview.png', ratio: 1200 / 600 } }
  ],

  logos: [
    { group: 'entité', sources: { black: '/logos/entite/alm_1c.svg', white: '/logos/entite/alm_white.svg', color: '/logos/entite/alm_2c.svg' }, preview: { src: '/logos/entite/alm_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/feva_1c.svg', white: '/logos/entite/feva_white.svg', color: '/logos/entite/feva_2c.svg' }, preview: { src: '/logos/entite/feva_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/fe_1c.svg', white: '/logos/entite/fe_white.svg', color: '/logos/entite/fe_2c.svg' }, preview: { src: '/logos/entite/fe_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/pmd_1c.svg', white: '/logos/entite/pmd_white.svg', color: '/logos/entite/pmd_2c.svg' }, preview: { src: '/logos/entite/pmd_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ersp_1c.svg', white: '/logos/entite/ersp_white.svg', color: '/logos/entite/ersp_2c.svg' }, preview: { src: '/logos/entite/ersp_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ga_1c.svg', white: '/logos/entite/ga_white.svg', color: '/logos/entite/ga_2c.svg' }, preview: { src: '/logos/entite/ga_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/alm_1c.svg', white: '/logos/appartenance/alm_white.svg', color: '/logos/appartenance/alm_2c.svg' }, preview: { src: '/logos/appartenance/alm_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/pd_1c.svg', white: '/logos/appartenance/pd_white.svg', color: '/logos/appartenance/pd_2c.svg' }, preview: { src: '/logos/appartenance/pd_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/ppdla_1c.svg', white: '/logos/appartenance/ppdla_white.svg', color: '/logos/appartenance/ppdla_2c.svg' }, preview: { src: '/logos/appartenance/ppdla_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/aidons_les_notres_1c.svg', white: '/logos/autres-marques-du-groupe/aidons_les_notres_white.svg', color: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/agmut_1c.svg', white: '/logos/autres-marques-du-groupe/agmut_white.svg', color: '/logos/autres-marques-du-groupe/agmut_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/agmut_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/arpege_1c.svg', white: '/logos/autres-marques-du-groupe/arpege_white.svg', color: '/logos/autres-marques-du-groupe/arpege_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/arpege_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/mdpj_1c.svg', white: '/logos/autres-marques-du-groupe/mdpj_white.svg', color: '/logos/autres-marques-du-groupe/mdpj_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/mdpj_2c.svg' } }
  ],

  pictos: (() => {
    // Import *_white pictos just so that vite registers them for the build stage
    import.meta.glob('@assets/pictos/*_white.svg', { eager: true, as: 'url' })
    return Object.values(import.meta.glob('@assets/pictos/*_black.svg', { eager: true, as: 'url' })).map(src => ({
      group: 'pictogrammes',
      sources: { black: src, white: src.replace('_black.svg', '_white.svg'), color: src },
      preview: { src }
    }))
  })(),

  textures: [
    ...(Store.env.debug.get() ? Object.values(import.meta.glob('@assets/debug/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'debug', src, preview: { ratio: 1 } })) : []),
    ...Object.values(import.meta.glob('@assets/alm/peoples/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'personnes', src, preview: { ratio: 1 } })),
    ...Object.values(import.meta.glob('@assets/alm/textures/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'textures', src, preview: { ratio: 1 } }))
  ],

  colors: [
    { value: '#381A0A', contrast: '#FFFFFF', logo: 'white', label: 'brun' },
    { value: '#00E8FF', contrast: '#381A0A', logo: 'black', label: 'bleu clair' },
    { value: '#F2F2F2', contrast: '#381A0A', logo: 'color', label: 'blanc cassé' },
    { value: '#29FF9B', contrast: '#381A0A', logo: 'black', label: 'vert' },
    { value: '#FF91DE', contrast: '#381A0A', logo: 'black', label: 'rose' },
    { value: '#F6261B', contrast: '#FFFFFF', logo: 'white', label: 'rouge' },
    { value: '#FFF078', contrast: '#381A0A', logo: 'black', label: 'jaune' },
    { value: '#0052FF', contrast: '#FFFFFF', logo: 'white', label: 'bleu foncé' },
    { value: '#FFFFFF', contrast: '#381A0A', logo: 'color', label: 'blanc' }
  ],

  formats: [
    Formats.debug,
    Formats.facebook,
    Formats.x,
    Formats.linkedin,
    Formats.print
  ]
    .filter(formats => formats.length > 0)
    .reduce((formats, group) => [...formats, ...group], [])
}))

/**
 * ALM Haut de gamme
 */
Routes.set('/haut-de-gamme', deepFreeze({
  portal: {
    logo: '/logos/haut-de-gamme/patrimoniale_1c.svg',
    images: [
      '/portal/haut-de-gamme-1.jpg',
      '/portal/haut-de-gamme-2.jpg'
    ]
  },

  defaults: {
    logo: data => data.logos[0],
    front: data => data.colors[1],
    background: data => data.textures[0],
    back: null // No back in haut-de-gamme because no folds
  },

  uploads: {
    formats: true,
    templates: false,
    logos: false,
    pictos: {
      multiple: true,
      accept: 'image/png, image/jpeg, image/svg+xml'
    },
    textures: {
      multiple: true,
      accept: 'image/png, image/jpeg'
    }
  },

  animation: {
    easing: 'expoInOut',
    duration: '2s',
    transition: {
      overlap: '1s',
      interpolations: {
        opacity: { from: 0, to: 1, duration: '1s', easing: 'expoOut' }
      }
    },
    blocks: {
      image: ['fade', 'fall', 'pan', 'pop', 'rise', 'pulse', 'rotate'],
      text: ['fade', 'fall', 'pan', 'pop', 'rise', 'typewriter', 'shift', 'pulse', 'rotate']
    }
  },

  templates: [
    { label: 'gabarit simple', src: '/templates/haut-de-gamme/default/index.html', preview: { src: '/templates/haut-de-gamme/default/preview.png', ratio: 1200 / 600 } },
    { label: 'gabarit double', src: '/templates/haut-de-gamme/double/index.html', preview: { src: '/templates/haut-de-gamme/double/preview.png', ratio: 1200 / 600 } },
    { label: 'gabarit double + marges', src: '/templates/haut-de-gamme/double-margin/index.html', preview: { src: '/templates/haut-de-gamme/double-margin/preview.png', ratio: 1200 / 600 } }
  ],

  logos: [
    { sources: { black: '/logos/haut-de-gamme/patrimoniale_1c.svg', white: '/logos/haut-de-gamme/patrimoniale_white.svg' }, preview: { src: '/logos/haut-de-gamme/patrimoniale_1c.svg' } }
  ],

  pictos: (() => {
    // Import *_white pictos just so that vite registers them for the build stage
    import.meta.glob('@assets/pictos/*_white.svg', { eager: true, as: 'url' })
    return Object.values(import.meta.glob('@assets/pictos/*_black.svg', { eager: true, as: 'url' })).map(src => ({
      group: 'pictogrammes',
      sources: { black: src, white: src.replace('_black.svg', '_white.svg'), color: src },
      preview: { src }
    }))
  })(),

  textures: [
    ...(Store.env.debug.get() ? Object.values(import.meta.glob('@assets/debug/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'debug', src, preview: { ratio: 1 } })) : []),
    ...Object.values(import.meta.glob('@assets/haut-de-gamme/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'haut de gamme', src, preview: { ratio: 1 } }))
  ],

  colors: [
    { value: '#381A0A', contrast: '#FFFFFF', logo: 'white', label: 'brun' },
    { value: '#FFFFFF', contrast: '#381A0A', logo: 'black', label: 'blanc' },
    { value: '#F2F2F2', contrast: '#381A0A', logo: 'black', label: 'blanc cassé' },
    { value: '#E4FDFF', contrast: '#381A0A', logo: 'black', label: 'bleu clair pastel (10%)' },
    { value: '#E9FFF5', contrast: '#381A0A', logo: 'black', label: 'vert pastel (10%)' },
    { value: '#FFF4FB', contrast: '#381A0A', logo: 'black', label: 'rose pastel (10%)' },
    { value: '#FFFEF1', contrast: '#381A0A', logo: 'black', label: 'jaune pastel (10%)' },
    { value: '#E4EDFF', contrast: '#381A0A', logo: 'black', label: 'bleu foncé pastel (10%)' }
  ],

  formats: [Formats.debug, Formats.linkedin, Formats.print]
    .filter(formats => formats.length > 0)
    .reduce((formats, group) => [...formats, ...group], [])
}))

// Export the correct configuration based on current route
export default (() => {
  const endpoint = Store.env.endpoint.get()
  if (Routes.has(endpoint)) {
    window.__data = Routes.get(endpoint)
    return window.__data
  }
})()
