/* global Blob */
import { writable } from '@tooooools/ui/state'

import Store from '/data/store'

import * as Api from '/controllers/Api'
import * as Animation from '/controllers/Animation'
import * as File from '/controllers/File'
import { error, log } from '/controllers/Toast'

import extractRasters from '/utils/svg-extract-rasters'
import embedRasters from '/utils/svg-embed-rasters'

export const progress = writable()

function handleProgress (message) {
  if (message?.event !== 'progress') return
  progress.set({
    percent: Math.round(message.percent * 100),
    message: {
      /* eslint-disable quote-props */
      'upload': 'Transfert du document…',
      'sharp': 'Rendu de la séquence…',
      'ffmpeg': 'Génération de la vidéo…',
      'gif': 'Génération du gif…'
    }[message.context]
  }, true)
}

export async function api (filetype, svg, parameters = {}) {
  try {
    const start = Date.now()
    const filename = Date.now() + '.' + filetype

    const upload = writable()
    upload.subscribe(handleProgress)
    Store.api.wss.message.subscribe(handleProgress)

    const file = await Api[filetype](svg, { filename, ...parameters }, upload)
    Store.api.wss.message.unsubscribe(handleProgress)

    if (Store.env.debug.get() || !Store.env.production.get()) {
      log(`Rendered in <b>${((Date.now() - start) / 1000).toFixed(3)}&thinsp;s</b>`, file.url)
    }

    return File.save(filename, file)
  } catch (err) {
    error('Une erreur est survenue lors de la communication avec l’API', err.message)
    console.error(err)
  }
}

export async function png () {
  // TODO select <Page> or zip multiple <Page>
  const template = Store.app.page.current?.props.template
  if (!template) throw new Error('No template found')

  const files = new Map()
  const svg = await template.toSVG({ rasterizeNestedSVG: false }, extractRasters(files))
  return api('png', svg.outerHTML, { files })
}

export async function svg () {
  // TODO select <Page> or zip multiple <Page>
  const template = Store.app.page.current?.props.template
  if (!template) throw new Error('No template found')

  const svg = await template.toSVG({ rasterizeNestedSVG: false }, embedRasters)
  return File.save(Date.now() + '.svg', { blob: new Blob([svg.outerHTML], { type: 'image/svg+xml' }) })
}

export async function pdf () {
  // TODO select <Page> or zip multiple <Page>
  const template = Store.app.page.current?.props.template
  if (!template) throw new Error('No template found')

  const files = new Map()
  const svg = await template.toSVG({ rasterizeNestedSVG: false }, extractRasters(files))
  return api('pdf', svg.outerHTML, { files })
}

export async function mp4 () {
  const { svg, timeline, files } = await Animation.create()
  return api('mp4', svg.outerHTML, { timeline, files })
}

export async function gif () {
  const { svg, timeline, files } = await Animation.create()
  return api('gif', svg.outerHTML, { timeline, files })
}
