/* global Blob, Image, OffscreenCanvas */

async function getRawSvg (href) {
  const resp = await window.fetch(href)
  const text = await resp.text()
  return (/<svg[\s\S]*\/svg>/.exec(text) ?? [])[0]
}

export default async function (href, {
  width,
  height,
  density = window.devicePixelRato ?? 1,
  background
} = {}) {
  const svgString = await getRawSvg(href)
  return new Promise(resolve => {
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml' })

    const image = new Image()
    image.onload = async function () {
      const ratio = this.naturalWidth / this.naturalHeight

      width = (width ?? this.naturalWidth) * density
      height = (height ?? this.naturalHeight) * density

      // Ensure ratio always matches the real file
      if (width / height !== ratio) {
        if (width >= height) height = width / ratio
        else width = height * ratio
      }

      const canvas = new OffscreenCanvas(width, height)
      const context = canvas.getContext('2d')
      if (background) {
        context.fillStyle = background
        context.fillRect(0, 0, canvas.width, canvas.height)
      }

      context.drawImage(image, 0, 0, canvas.width, canvas.height)

      URL.revokeObjectURL(this.src)
      if (window.OffscreenCanvas) resolve(await canvas.convertToBlob({ type: 'image/png', quality: 1 }))
      else canvas.toBlob(blob => resolve(blob), 'image/png', 1)
    }

    image.src = URL.createObjectURL(svgBlob)
  })
}
