import { Convert } from '@tooooools/utils'

export default async (element, svgElement) => {
  if (!svgElement) return

  // Link all <image> to their external file
  const images = svgElement.tagName === 'svg'
    ? svgElement.querySelectorAll('image[href][data-filename]')
    : svgElement.tagName === 'image'
      ? [svgElement]
      : []

  for (const image of images) {
    const href = image.getAttribute('href')
    if (!href) continue
    image.setAttribute('href', await Convert.objectURL(href).toDataURL())
  }

  return svgElement
}
