import { render } from '@tooooools/ui'
import { derived, writable } from '@tooooools/ui/state'

import hash from 'object-hash'

import Data from '/data/static'
import Store from '/data/store'
import * as Icons from '/data/icons'

import { error } from '/controllers/toast'

import {
  Button,
  Input,
  Modal,
  Select,
  Toolbar
} from '@tooooools/ui/components'

const toSelectOption = format => format === 'hr'
  ? Select.separator
  : { value: format, group: format.group, label: format.label }

export const selectValue = derived(Store.document.format, v => v)
export const selectLabel = derived(Store.document.format, v => v ? `${v.group}\u2009/\u2009${v.label}` : '')
export const selectCompare = (a, b) => a?.width === b?.width && a?.height === b?.height
export const selectOptions = derived(Store.document.formats, (customs = []) => {
  return [
    ...(Data?.formats ?? []).map(toSelectOption),
    ...customs.map(toSelectOption),
    ...(Data?.uploads.formats ? [Select.separator, { value: 'add', label: '+ ajouter un format' }] : [])
  ].filter(Boolean)
})

async function create ({
  label = '',
  width = 0,
  height = 0,
  unit = 'px',
  addLabel = 'ajouter'
} = {}) {
  const $label = writable(label)
  const $width = writable(width)
  const $height = writable(height)
  const $unit = writable(unit)

  const ok = await new Promise(resolve => {
    let modal
    const quit = ok => e => {
      e.stopPropagation()
      if (ok && $width.get() <= 0) return error('La largeur du format doit être supérieure à zéro', null, { duration: 5000 })
      if (ok && $height.get() <= 0) return error('La hauteur du format doit être supérieure à zéro', null, { duration: 5000 })
      if (ok && $unit.get() === 'px' && $height.get() % 2 !== 0) return error('La hauteur des formats en pixels doit être divisible par deux', null, { duration: 5000 })
      modal?.destroy()
      resolve(ok)
    }

    const roundDimension = $signal => () => $unit.get() === 'px' && $signal.update(Math.round)
    $unit.subscribe(roundDimension($width))
    $unit.subscribe(roundDimension($height))

    render(
      <Modal
        title='Ajouter un format'
        class='modal--add-format'
        ref={t => { modal = t }}
        event-close={resolve}
      >
        <div class='modal--add-format__content'>
          <Input autofocus store-value={$label} type='text' name='label' placeholder='nom du format' />
          <fieldset>
            <Input before='largeur' store-value={$width} type='number' name='width' min={0} event-blur={roundDimension($width)} />
            <Input before='hauteur' store-value={$height} type='number' name='height' min={0} event-blur={roundDimension($height)} />
            <Select
              store-value={$unit}
              options={['px', 'mm', 'cm'].map(value => ({ value, label: value }))}
            />
          </fieldset>
          <Toolbar class='modal--add-format__toolbar'>
            <Button
              icon={Icons.close}
              label='annuler'
              event-click={quit(false)}
            />
            <Button
              active
              icon={Icons.ok}
              label={addLabel}
              event-click={quit(true)}
            />
          </Toolbar>
        </div>
      </Modal>
    )
  })

  return ok && {
    group: 'format personnalisé',
    label: $label.get() || `${$width.get()}\u2009×\u2009${$height.get()}\u2009${$unit.get()}`,
    width: $width.get(),
    height: $height.get(),
    unit: $unit.get()
  }
}

export async function handleChange (e, select) {
  let format = select.state.value.get()
  if (!format) return

  if (format === 'add') {
    selectValue.set(selectValue.previous)

    format = await create()
    if (!format) return
    Store.document.formats.update(formats => [...formats, format])
  }

  Store.document.format.set(format)
}

export async function updateCurrent () {
  const previousFormat = Store.document.format.get()
  if (!previousFormat) return

  const newFormat = await create({ ...previousFormat, addLabel: 'modifier' })
  if (!newFormat) return

  Store.document.formats.update(formats => {
    const index = formats.findIndex(f => hash(f) === hash(previousFormat))
    if (index > -1) formats[index] = newFormat
    return formats
  }, true)

  Store.document.format.set(newFormat)
}
