export const group = 'objet'
export const value = 'rise'
export const label = 'lever'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)
  const easing = duration < 60 ? 'sineInOut' : 'expoInOut'

  const { height } = svgElement.getBBox()

  return {
    ['#' + svgElement.id]: {
      opacity: { from: 0, to: 1, delay, duration, easing },
      translateY: { from: height / 2, to: 0, delay, duration, easing }
    }
  }
}
