export default ([[x1, y1], [x2, y2]]) => {
  let nx = x2 - x1
  let ny = y2 - y1

  const len = Math.sqrt(nx * nx + ny * ny)
  nx /= len
  ny /= len
  return isNaN(ny) || isNaN(nx)
    ? null
    : [-ny, nx]
}
