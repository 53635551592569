import { Convert } from '@tooooools/utils'
import UUID from '/data/uuid'
import svgToPng from '/utils/svg-to-png'

/**
 * Helpers used as a callback of @tooooools/html-to-svg#render
 * Extract and convert all rasters as Blob into a file Map, keeping a reference
 * in the source element using a data-href attribute
 */

export default files => async (element, svgElement) => {
  if (!svgElement) return

  // Externalize canvas
  if (element.tagName === 'CANVAS') {
    const filename = UUID.canvas()
    const blob = await Convert.dataURL(svgElement.getAttribute('href')).toBlob()

    files.set(filename, blob)
    svgElement.removeAttribute('href')
    svgElement.setAttribute('data-href', filename)
    return svgElement
  }

  // Link all <image> to their external file
  const images = svgElement.tagName === 'svg'
    ? svgElement.querySelectorAll('image[href][data-filename]')
    : svgElement.tagName === 'image'
      ? [svgElement]
      : []

  for (const image of images) {
    const filename = image.getAttribute('data-filename') ?? element.getAttribute('data-filename')
    const href = image.getAttribute('href')

    if (filename && href) {
      const blob = files.get(filename) ?? (
        filename.endsWith('.svg')
          // @tooooools/api/svg/video does not handle SVGImage inside <svg>
          ? await svgToPng(href, {
            width: element.offsetWidth,
            height: element.offsetHeight,
            density: 2
          })
          : await Convert.objectURL(href).toBlob()
      )

      // Store the blob for Api.video
      files.set(filename, blob)

      // Use data-href as a proxy to prevent browser from loading the file
      image.removeAttribute('href')
      image.setAttribute('data-href', filename)
    }
  }

  return svgElement
}
