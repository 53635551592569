import { Convert } from '@tooooools/utils'
import { derived } from '@tooooools/ui/state'

import Worker from '/worker?worker'

import Store from '/data/store'
import Data from '/data/static'
import * as Comlink from 'comlink'
import * as IDB from 'idb-keyval'

import Template from '/abstractions/Template'

import * as Actions from '/controllers/Actions'
import * as File from '/controllers/File'
import { error } from '/controllers/Toast'

const worker = Comlink.wrap(new Worker())
const CACHE = new Map()
const SKIP_DATAURL = '<dataURL not included>'

export const isAnimated = derived(Store.document.lastTouched, () => {
  if (Store.document.pages.current.size > 1) return true
  if (document.body.querySelectorAll('[data-animation]').length) return true
  return false
})

export const data = {
  isEmpty: ({ checkFormats = true, checkAssets = true } = {}) => {
    if (checkFormats && Store.document.formats.get()?.length) return false
    if (checkAssets) {
      const assets = Store.document.assets.get()
      for (const type in assets) {
        if (assets[type]?.size) return false
      }
    }

    return !(Store.document.pages.get()?.size > 0)
  },

  // Write the document to a data object
  dump: async ({ includeAssets = false } = {}) => {
    return {
      timestamp: Date.now(),
      version: Store.env.version.get(),
      endpoint: Store.env.endpoint.get(),
      format: Store.document.format.get(),
      formats: Store.document.formats.get(),
      pages: Array.from(Store.document.pages.get()).reduce((pages, [id, template]) => {
        pages[id] = template.toJSON()
        return pages
      }, {}),
      assets: await (async () => {
        // TODO[next] ask users if they want to save only used assets
        const assets = {}
        for (const type in Store.document.assets.get()) {
          assets[type] = {}
          for (const [filename, objectURL] of Store.document.assets.current[type]) {
            assets[type][filename] = includeAssets
              ? await (async () => {
                if (!CACHE.has(objectURL)) CACHE.set(objectURL, await Convert.objectURL(objectURL).toDataURL())
                return CACHE.get(objectURL)
              })()
              : SKIP_DATAURL
          }
        }
        return assets
      })()
    }
  },

  reset: () => {
    Actions.destroyPreview()

    Store.app.xray.reset()

    Store.app.context.reset()
    Store.app.block.reset()
    Store.app.page.reset()

    Store.document.lastTouched.set(Date.now())
    Store.document.format.set(Data.formats[0])
    Store.document.formats.reset()
    Store.document.pages.reset()
    Store.document.assets.reset()
  },

  // Load the document from a data object
  load: async data => {
    if (!data) return
    Actions.destroyPreview()

    // TODO check for endpoint mismatch
    // TODO[next] warn user if data.version differs a lot from current version
    if (data.format) {
      Store.document.format.set(data.format)
    }

    if (data.formats) {
      Store.document.formats.set(data.formats)
    }

    if (data.assets) {
      const assets = Store.document.assets.get()
      for (const type in data.assets) {
        if (!assets[type]) assets[type] = new Map()
        for (const [filename, dataURL] of Object.entries(data.assets[type])) {
          if (dataURL === SKIP_DATAURL) continue
          const objectURL = await Convert.dataURL(dataURL).toObjectURL()
          assets[type].set(filename, objectURL)
        }
      }
      Store.document.assets.set(assets, true)
    }

    if (data.pages) {
      const pages = new Map()
      for (const [id, o] of Object.entries(data.pages)) {
        pages.set(id, new Template({ ...o, id }))
      }

      // Replace the whole document
      Store.document.pages.update(pages => pages.clear())
      Store.document.pages.set(pages)
    }
  }
}

export const session = {
  check: async (endpoint = Store.env.endpoint.get()) => {
    try {
      const json = await IDB.get(endpoint)
      if (!json) return

      const data = JSON.parse(json)
      return data?.timestamp
    } catch (err) {
      console.error(err)
    }
  },

  // Write the document in IndexedDB
  save: async () => {
    Store.app.saving.set(true)

    const json = await data.dump({ includeAssets: true })
    await worker.storeToIDB(Store.env.endpoint.get(), JSON.stringify(json))

    Store.app.saving.set(false)
  },

  // Load the document from IndexedDB
  load: async (endpoint = Store.env.endpoint.get()) => {
    try {
      const json = await IDB.get(endpoint)
      await data.load(JSON.parse(json))
    } catch (err) {
      error('Une erreur est survenue lors de la restoration du document', err.message)
      console.error(err)
    }
  }
}

export const file = {
  // Write the document to .alm file
  save: async () => {
    const json = await data.dump({ includeAssets: true })
    return File.save(Date.now() + '.alm', { json })
  },

  // Load the document from a .alm file
  load: async () => {
    const files = await File.browse({ accept: '.alm' })
    if (!files || !files.length) return

    try {
      const json = await File.load(files[0])
      await data.load(JSON.parse(json))
    } catch (err) {
      error('Une erreur est survenue lors du chargement du fichier', err.message)
      console.error(err)
    }
  }
}
