// SEE http://paulbourke.net/geometry/pointlineplane/
export default ([[x1, y1], [x2, y2]], [[x3, y3], [x4, y4]]) => {
  const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)
  if (denom === 0) return

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom
  const x = x1 + ua * (x2 - x1)
  const y = y1 + ua * (y2 - y1)
  return (isNaN(x) || isNaN(y)) ? null : [x, y]
}
