import './Page.scss'
import { Component } from '@tooooools/ui'
import { not, derived, readable, writable } from '@tooooools/ui/state'

import Store from '/data/store'
import Data from '/data/static'
import * as Icons from '/data/icons'

import * as Actions from '/controllers/Actions'
import * as BlockController from '/controllers/Block'
import { warn } from '/controllers/Toast'

import {
  Button,
  Input,
  Toolbar
} from '@tooooools/ui/components'
import Block from '/components/Block'
import Context from '/components/Context'
import Foldable from '/components/Foldable'
import Image from '/components/Block/Image'
import Text from '/components/Block/Text'
import TextureBlock from '/components/Block/Texture'

import getAncestorAttr from '/utils/dom-get-ancestor-attribute-value'

export const CACHE = new Map()

export default class PageComponent extends Component {
  beforeRender (props) {
    this.touch = this.touch.bind(this)

    this.handleActive = this.handleActive.bind(this)
    this.handleContext = this.handleContext.bind(this)
    this.handleLock = this.handleLock.bind(this)
    this.handleFolded = this.handleFolded.bind(this)
    this.handleFormat = this.handleFormat.bind(this)
    this.handleTextures = this.handleTextures.bind(this)
    this.handleFontScale = this.handleFontScale.bind(this)

    if (!props.template) throw new Error('The <Page> component requires a template prop')

    this.state = {
      index: derived([Store.app.page, Store.document.pages], () => Array.from(Store.document.pages.current.keys()).indexOf(Store.app.page.current?.props?.template.id)),
      loading: writable(true),
      active: derived(Store.app.page, t => t === this),
      folded: writable(false),

      // Used in controllers/Page to retrieve state, do not populate
      frontTexture: writable(),
      backTexture: writable(),
      backgroundTexture: writable(),
      hasFront: writable(),
      hasBack: writable(),
      hasBackground: writable(),
      hasFrontImageTemplate: writable(),
      hasFrontTextTemplate: writable(),
      hasFrontButtonTemplate: writable(),
      hasBackgroundImageTemplate: writable(),
      hasBackgroundTextTemplate: writable(),
      hasBackgroundButtonTemplate: writable(),

      needsXray: writable(),
      template: readable(props.template),
      fontScale: derived(props.template.state.fontScale, v => v),
      duration: derived(props.template.state.duration, v => v)
    }
  }

  template (props, state) {
    return (
      <div
        class='page'
        store-class-is-active={state.active}
        store-class-is-locked={props.template.state.locked}
        store-class-is-loading={state.loading}
        store-class-is-folded={state.folded}
        event-click={() => Store.app.page.set(this)}
      >
        <Toolbar class='page__toolbar prevent-context-change'>
          <Toolbar class='page__name'>
            <span
              class='page__count'
              store-hidden={derived(Store.document.pages, pages => pages.size <= 1)}
            >
              <span store-text={derived(state.index, i => i + 1)} />
              <span store-text={derived(Store.document.pages, pages => pages.size)} />
            </span>
            <Input
              editOnDblClick
              size='auto'
              type='text'
              class='page__input--name'
              data-title='Cliquer pour sélectionner la page, double-cliquer pour éditer son nom'
              store-value={props.template.state.name}
              placeholder={props.template.props.label}
              event-click={e => Store.app.context.set('page')}
              event-focus={e => Store.app.context.set('page')}
            />
            {Store.env.debug.get() && <span>{props.template.id}</span>}
          </Toolbar>
          <Toolbar>
            <Toolbar store-hidden={derived(Store.document.pages, pages => pages.size <= 1)}>
              <Button
                icon={Icons.moveLeft}
                store-disabled={not(state.index)}
                title='Déplacer ce gabarit vers la gauche'
                event-click={() => Actions.movePage(-1, this)}
              />
              <Button
                icon={Icons.moveRight}
                store-disabled={derived([Store.document.pages, state.index], () => state.index.get() >= Store.document.pages.current.size - 1)}
                title='Déplacer ce gabarit vers la droite'
                event-click={() => Actions.movePage(+1, this)}
              />
            </Toolbar>

            <Toolbar compact>
              <Button
                icon={Icons.lock}
                title='Déverrouiller le gabarit'
                store-hidden={not(props.template.state.locked)}
                event-click={BlockController.exec('focus', () => props.template.state.locked.set(false))}
              />
              <Button
                active
                icon={Icons.unlock}
                title='Verrouiller le gabarit'
                store-hidden={props.template.state.locked}
                event-click={BlockController.exec('focus', () => props.template.state.locked.set(true))}
              />
            </Toolbar>

            <Button
              icon={Icons.delete}
              title='Supprimer le gabarit'
              event-click={e => Actions.confirm(() => Actions.deletePage(this), {
                message: <p>Supprimer le gabarit ? Cette action ne peut être annulée.</p>,
                confirm: {
                  icon: Icons.delete,
                  label: 'supprimer'
                }
              })}
            />

            <Button
              icon={Icons.clone}
              event-click={() => {
                Actions.clonePage(this)
                window.requestAnimationFrame(() => Actions.selectPage(+1))
              }}
              title='Dupliquer ce gabarit'
            />

            <Button
              icon={Icons.addTemplate}
              event-click={() => Actions.insertPage(state.index.get() + 1)}
              title='Ajouter un nouveau gabarit directement après celui-ci'
            />
          </Toolbar>
        </Toolbar>
      </div>
    )
  }

  afterRender (props) {
    // Update current page
    Store.app.page.set(this, true)

    // Directly edit this page if not in animation (grid) mode
    if (!Context.match('timeline', Store.app.context.get())) Store.app.context.set('page')

    Store.app.context.subscribe(this.handleContext)
    Store.document.format.subscribe(this.handleFormat)
    this.state.folded.subscribe(this.handleFolded)
    this.state.active.subscribe(this.handleActive)

    props.template.state.locked.subscribe(this.handleLock)
    props.template.state.textures.subscribe(this.handleTextures)
    props.template.state.fontScale.subscribe(this.handleFontScale)
  }

  async afterMount () {
    // Keep a reference of this <Page> in the Template abstraction
    this.props.template.page = this

    await this.props.template.fetch()

    // Update internal states
    this.refs.template = this.props.template.element
    this.state.hasFront.set(this.props.template.hasFront)
    this.state.hasBack.set(this.props.template.hasBack)
    this.state.hasBackground.set(this.props.template.hasBackground)
    this.state.hasFrontTextTemplate.set(this.props.template.hasFrontTextTemplate)
    this.state.hasFrontButtonTemplate.set(this.props.template.hasFrontTextTemplate)
    this.state.hasFrontImageTemplate.set(this.props.template.hasFrontImageTemplate)
    this.state.hasBackgroundTextTemplate.set(this.props.template.hasBackgroundTextTemplate)
    this.state.hasBackgroundButtonTemplate.set(this.props.template.hasBackgroundTextTemplate)
    this.state.hasBackgroundImageTemplate.set(this.props.template.hasBackgroundImageTemplate)
    this.state.needsXray.set(this.props.template.needsXray)

    this.render(this.refs.template, this.base)

    // Decorate all contenteditable
    for (const element of this.refs.template.querySelectorAll('[contenteditable]:not([data-template])')) {
      this.insertText(element, {}, { insertBefore: element.nextSibling })
    }

    // Attach a Context to each part
    for (const element of this.refs.template.querySelectorAll('[data-part]')) {
      this.render((
        <Context name={`part:${getAncestorAttr(element, 'data-part')}`}>
          {element}
        </Context>
      ), element.parentNode)
    }

    // Decorate all logos
    for (const element of this.refs.template.querySelectorAll('img.logo:not([data-template])')) {
      this.insertImage(element, {
        title: 'Double-cliquer pour changer le logo',
        context: 'object:logo',
        ref: this.refArray(`image-${getAncestorAttr(element, 'data-part') ?? 'front'}`),
        sources: Data.defaults.logo(Data).sources,
        'event-dblclick': BlockController.exec('setSources', Actions.selectLogo)
      }, { insertBefore: element.nextSibling })
    }

    // Decorate foldable elements
    for (const element of this.refs.template.querySelectorAll('[data-folds]')) {
      this.render((
        <Foldable
          ref={this.refArray('foldables')}
          template-slot={this.props.template.createSlot(element)}
          target={element}
          store-folded={this.state.folded}
          event-release={this.touch}
        />
      ), this.refs.template)
    }

    // Find Block templates and hide them
    for (const element of this.refs.template.querySelectorAll('[data-template]')) {
      element.style.display = 'none'
      element.classList.add('no-export')
    }

    // Handle stored user images and user texts
    for (const [slot, data] of this.props.template.slots) {
      if (slot.startsWith('userimages')) {
        this.insertImage(undefined, {
          part: (slot.match(/userimages\[(.*)\]\[\d+\]/) ?? [])[1],
          sources: data.sources,
          context: 'object:picto',
          'template-slot': this.props.template.createSlot(undefined, slot),
          'event-dblclick': BlockController.exec('setSources', Actions.selectPicto)
        })
      }

      if (slot.startsWith('usertexts')) {
        this.insertText(undefined, {
          part: slot.match((/usertexts\[(.*)\]\[\d+\]/) ?? [])[1],
          'template-slot': this.props.template.createSlot(undefined, slot)
        })
      }

      if (slot.startsWith('userbuttons')) {
        this.insertButton(undefined, {
          part: slot.match((/userbuttons\[(.*)\]\[\d+\]/) ?? [])[1],
          'template-slot': this.props.template.createSlot(undefined, slot)
        })
      }
    }

    this.touch()
    this.reflow()
    this.handleLock()
    this.handleTextures()
    this.handleFontScale()
    this.handleFolded()
    this.state.loading.set(false)
  }

  insertText (element, props = {}, target) {
    // If no element given, try inserting a blank text based on [data-template=text] found in the Page Template
    if (!element) {
      const source = this.base.querySelector(props.part
        ? `[data-part="${props.part}"] [data-template="text"]`
        : '[data-template="text"]'
      )

      if (!source) {
        warn('Ce gabarit ou une partie de ce gabarit ne supporte pas l’ajout de texte personnalisé, certains textes seront ignorés')
        return
      }

      const clone = source.cloneNode(true)
      clone.style.display = ''
      clone.classList.remove('no-export')
      clone.removeAttribute('data-template')

      if (source.hasAttribute('data-template-trigger-xray') && !Store.app.xray.get()) {
        Store.app.xray.set(true)
        warn('Le mode <b>rayons X</b> a été activé pour simplifier la manipulation de l’élement ajouté', null, { duration: 5000 })
      }

      return this.insertText(clone, { ...props, locked: false }, source.parentNode)
    }

    return this.render((
      <Context name='object:text'>
        <Block
          move={element.dataset.move}
          resize={element.dataset.resize}
          ref={props.ref ?? this.refArray('blocks')}
          template-slot={props['template-slot'] ?? this.props.template.createSlot(element)}
          title='Double-cliquer pour éditer le texte'
          container={element.parentNode}
          event-update={this.touch}
          event-dblclick={BlockController.exec('editText')}
          {
            ...(props.locked !== undefined
              ? { locked: props.locked }
              : { 'store-locked': this.props.template.state.locked }
            )
          }
        >
          <Text
            template-slot={this.props.template.createSlot(element)}
            event-blur={this.touch}
          >
            {element}
          </Text>
        </Block>
      </Context>
    ), target)
  }

  insertButton (element, props = {}, target) {
    // If no element given, try inserting a blank button based on [data-template=button] found in the Page Template
    if (!element) {
      const source = this.base.querySelector(props.part
        ? `[data-part="${props.part}"] [data-template="button"]`
        : '[data-template="button"]'
      )

      if (!source) {
        warn('Ce gabarit ou une partie de ce gabarit ne supporte pas l’ajout de call to action personnalisé, certains call to action seront ignorés')
        return
      }

      const clone = source.cloneNode(true)
      clone.style.display = ''
      clone.classList.remove('no-export')
      clone.removeAttribute('data-template')

      if (source.hasAttribute('data-template-trigger-xray') && !Store.app.xray.get()) {
        Store.app.xray.set(true)
        warn('Le mode <b>rayons X</b> a été activé pour simplifier la manipulation de l’élement ajouté', null, { duration: 5000 })
      }

      return this.insertText(clone, { ...props, locked: false }, source.parentNode)
    }

    return this.render((
      <Context name='object:button'>
        <Block
          move={element.dataset.move}
          resize={element.dataset.resize}
          ref={props.ref ?? this.refArray('blocks')}
          template-slot={props['template-slot'] ?? this.props.template.createSlot(element)}
          title='Double-cliquer pour éditer le texte'
          container={element.parentNode}
          event-update={this.touch}
          event-dblclick={BlockController.exec('editText')}
          {
            ...(props.locked !== undefined
              ? { locked: props.locked }
              : { 'store-locked': this.props.template.state.locked }
            )
          }
        >
          <Text
            template-slot={this.props.template.createSlot(element)}
            event-blur={this.touch}
          >
            {element}
          </Text>
        </Block>
      </Context>
    ), target)
  }

  insertImage (element, props = {}, target) {
    // If no element given, try inserting a blank image based on [data-template=image] found in the Page Template
    if (!element) {
      const source = this.base.querySelector(props.part
        ? `[data-part="${props.part}"] [data-template="image"]`
        : '[data-template="image"]'
      )

      if (!source) {
        warn('Ce gabarit ou une partie de ce gabarit ne supporte pas l’ajout d’image personnalisée, certaines images seront ignorées')
        return
      }

      const clone = source.cloneNode(true)
      clone.style.display = ''
      clone.classList.remove('no-export')
      clone.removeAttribute('data-template')

      if (source.hasAttribute('data-template-trigger-xray') && !Store.app.xray.get()) {
        Store.app.xray.set(true)
        warn('Le mode <b>rayons X</b> a été activé pour simplifier la manipulation de l’élement ajouté', null, { duration: 5000 })
      }

      return this.insertImage(clone, {
        ...props,
        locked: false,
        ref: this.refArray(`image-${getAncestorAttr(source, 'data-part') ?? 'front'}`)
      }, source.parentNode)
    }

    return this.render((
      <Context name={props.context}>
        <Block
          move={element.dataset.move}
          resize={element.dataset.resize}
          ref={this.refArray('blocks')}
          template-slot={props['template-slot'] ?? this.props.template.createSlot(element)}
          title={props.title}
          container={element.parentNode}
          event-update={this.touch}
          event-dblclick={props['event-dblclick']}
          {
            ...(props.locked !== undefined
              ? { locked: props.locked }
              : { 'store-locked': this.props.template.state.locked }
            )
          }
        >
          <Image
            ref={props.ref}
            template-slot={props['template-slot'] ?? this.props.template.createSlot(element)}
            sources={props.sources}
            event-update={this.touch}
          >
            {element}
          </Image>
        </Block>
      </Context>
    ), target)
  }

  touch () {
    if (Store.env.debug.get()) this.log('touched.')

    const now = Date.now()
    Store.document.lastTouched.set(now)
    this.props.template.state.lastTouched.set(now)
  }

  reflow () {
    this.refs.template.classList.add('is-resizing')
    for (const block of this.refs.blocks ?? []) block?.reflow()
    for (const foldable of this.refs.foldables ?? []) foldable.reflow()
    window.requestAnimationFrame(() => this.refs?.template.classList.remove('is-resizing'))
  }

  handleActive () {
    if (!this.state.active.get()) return
    this.reflow()
  }

  handleContext () {
    for (const part of this.refs.template.querySelectorAll('[data-part]')) {
      part.classList.toggle('is-active', Context.match(`part:${part.dataset.part}`))
    }
  }

  handleFolded () {
    this.state.hasBack.set(this.state.folded.get())
  }

  handleFormat () {
    this.reflow()
    this.touch()
    Store.app.block.set(null)
  }

  handleLock () {
    if (this.props.template.state.locked.get()) return

    // Extract all <Block>#base from the CSS layout flow so that they can be
    // dragged without impacting their siblings
    for (const block of (this.refs.blocks ?? []).filter(Boolean)
      // Sort by offsetParent index so that extracting an element does not impact
      // subsequent elements in the same flow
      .sort((a, b) => Array.from(b.base.offsetParent.childNodes).indexOf(b.base) - Array.from(a.base.offsetParent.childNodes).indexOf(a.base))
    ) {
      if (block.props.move) block.unlock()
    }

    this.reflow()
  }

  async handleTextures () {
    const textures = this.props.template.state.textures.get()
    for (const part in textures) {
      const texture = textures[part]
      if (!texture) continue
      await texture.load()

      // Update current page state
      this.state[part + 'Texture'].set(texture)

      // Apply texture to all relevant nodes
      for (const node of [
        ...(this.refs.template?.querySelectorAll(`[data-part="${part}`) ?? []),
        ...(this.refs[`image-${part}`] ?? []),
        ...(part === 'back' ? this.refs.foldables ?? [] : [])
      ]) {
        texture.paint(node, {
          'event-update': this.touch,
          ref: this.refArray('blocks'),
          render: this.render.bind(this)
        })
      }
    }

    this.touch()
  }

  handleFontScale () {
    this.refs.template.style.setProperty('--font-scale', this.props.template.state.fontScale.get())
    for (const block of this.refs.blocks ?? []) {
      if (block instanceof TextureBlock) block.update()
    }
    this.touch()
  }

  beforeDestroy () {
    this.state.folded.unsubscribe(this.handleFolded)
    this.state.active.unsubscribe(this.handleActive)
    this.props.template.state.locked.unsubscribe(this.handleLock)
    this.props.template.state.textures.unsubscribe(this.handleTextures)
    this.props.template.state.fontScale.unsubscribe(this.handleFontScale)
    Store.app.context.unsubscribe(this.handleContext)
    Store.document.format.unsubscribe(this.handleFormat)
  }
}
