import './Toast.scss'
import { Component, render } from '@tooooools/ui'
import { writable } from '@tooooools/ui/state'

import classnames from 'classnames'

import * as Icons from '/data/icons'

import { Button } from '@tooooools/ui/components'

import noop from '/utils/noop'

export default class ToastComponent extends Component {
  static get container () {
    return document.querySelector('.toast-container') ?? render(<div class='toast-container' />, document.body).nodes[0]
  }

  beforeRender () {
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      quantity: writable(1)
    }
  }

  template (props, state) {
    return (
      <div
        class={classnames('toast', props.class, { 'has-duration': props.duration })}
        data-tone={props.tone || 'neutral'}
        store-data-quantity={state.quantity}
        style={`
          --toast-duration: ${props.duration ?? -1}ms
        `}
      >
        <div
          class='toast__icon'
          store-data-quantity={state.quantity}
          innerHTML={props.icon ?? Icons.alert}
        />

        <div
          class='toast__content'
          innerHTML={String(props.message ?? '').trim().split(/\n{2}/g).map(str => `<p>${str}</p>`).join('').replace(/\n/g, '<br/>')}
        />

        <Button
          icon={Icons.close}
          class='toast__btn--close'
          title='Effacer la notification'
          event-click={this.handleClick}
        />
      </div>
    )
  }

  afterMount () {
    if (this.props.duration) {
      this.refs.timer = window.setTimeout(() => this.destroy(), this.props.duration)
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.destroy()
  }

  beforeDestroy () {
    ;(this.props['event-close'] || noop)()
    if (this.refs.timer) window.clearTimeout(this.refs.timer)
  }
}
