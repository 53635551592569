import './Timeline.scss'
import { Component } from '@tooooools/ui'
import { not, writable, derived } from '@tooooools/ui/state'

import { Sortable, AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'

import Store from '/data/store'
import * as Icons from '/data/Icons'

import * as Actions from '/controllers/Actions'

import { Button } from '@tooooools/ui/components'
import Context from '/components/Context'
import Thumbnail from '/components/Thumbnail'

Sortable.mount(new AutoScroll())

export default class TimelineComponent extends Component {
  beforeRender (props) {
    this.update = this.update.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleSortStart = this.handleSortStart.bind(this)

    this.refs.pages = new Map()

    this.state = {
      sorting: writable(false),
      scrollTop: writable(0),
      hidden: not(Context.matchSignal('timeline')),
      empty: derived(Store.document.pages, pages => !pages.size)
    }
  }

  template (props, state) {
    return (
      <section
        class='timeline'
        store-class-is-hidden={state.hidden}
        store-class-is-sorting={state.sorting}
        store-class-is-empty={state.empty}
        event-scroll={this.handleScroll}
      >
        <div
          class='timeline__container'
          ref={this.ref('container')}
        >
          <div
            class='add'
            ref={this.ref('add')}
            event-click={Actions.addPage}
          >
            <Button
              class='btn--add'
              tabindex={-1}
              icon={Icons.addTemplate}
            />
          </div>
        </div>
      </section>
    )
  }

  afterMount () {
    this.state.hidden.subscribe(this.update)
    Store.document.pages.subscribe(this.update)

    this.refs.sortables = Sortable.create(this.refs.container, {
      filter: '.add',
      onStart: this.handleSortStart,
      onEnd: this.handleSort,
      // Ensure .add stays at the end
      onChange: e => this.refs.container.appendChild(this.refs.add),
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
      bubbleScroll: true
    })
  }

  update () {
    const pages = Store.document.pages.get()

    // Clear obsolete pages
    for (const [id, page] of this.refs.pages) {
      if (pages.has(id)) continue
      page.destroy()
      this.refs.pages.delete(id)
    }

    // Add new pages
    for (const [id, template] of pages) {
      this.render((
        this.refs.pages.has(id)
          // Reinsert page base to ensure correct order
          ? this.refs.pages.get(id).base
          : <Thumbnail
              ref={this.refMap(id, 'pages')}
              template={template}
              title='Cliquer pour sélectionner, déplacer pour changer l’ordre, double-cliquer pour éditer'
              store-label={derived(template.state.name, name => name || template.props.label)}
              store-active={derived(Store.app.page, page => page?.props.template === template)}
              event-click={() => Store.app.page.set(template.page)}
              event-dblclick={() => Store.app.context.set('page')}
            />
      ), { insertBefore: this.refs.add })
    }

    // Ensure scroll does not change due to subtree modifications
    this.base.scrollTop = this.state.scrollTop.get()
  }

  handleScroll (e) {
    this.state.scrollTop.set(this.base.scrollTop)
  }

  handleSortStart () {
    this.state.sorting.set(true)
  }

  handleSort (e) {
    // Replace the pages Map (Map order is based on insertion order)
    Store.document.pages.update(pages => {
      const sorted = new Map()
      for (const el of e.to.querySelectorAll('.thumbnail')) {
        const [id] = Array.from(this.refs.pages).find(([id, ref]) => ref.base === el)
        sorted.set(id, pages.get(id))
      }
      return sorted
    }, true)

    this.state.sorting.set(false)
  }

  beforeDestroy () {
    this.refs.sortables?.destroy()
  }
}
