const sanitize = string => string
  // Strip accents
  .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // Replace non-matching chars by -
  .replace(/[^a-zA-Z0-9.]/g, '-')
  // Dedup successive -
  .replace(/(-+)/g, '-')
  // Remove leading and trailing -
  .replace(/^-|-$/, '')
  .toLowerCase()
  // Handle ENAMETOOLONG
  .substr(0, 80)

function slugify (filename, { preserveExtension = true } = {}) {
  const ext = preserveExtension && filename.includes('.')
    ? sanitize('.' + filename.split('.').pop())
    : ''

  return sanitize(filename.replace(ext, '')) + ext
}

export default slugify
