import { SVGTimeline } from '@tooooools/utils'
import { derived } from '@tooooools/ui/state'

import Store from '/data/store'
import Data from '/data/static'
import UUID from '/data/uuid'

import * as Animations from '/controllers/Animations'
import * as Page from '/controllers/Page'

import extractRasters from '/utils/svg-extract-rasters'
import createSVG from '/utils/svg-create-element'
import renameObjectKey from '/utils/object-rename-key'
import getAncestorAttr from '/utils/dom-get-ancestor-attribute-value'

export const duration = derived([
  Store.document.pages,
  Page.state('lastTouched'),
  Page.state('duration')
], () => {
  let sum = 0
  for (const [, template] of Store.document.pages.get()) {
    sum += template.state.duration.get() ?? 0
  }
  return sum
})

export async function create ({
  destination = 'server' // 'browser'|'server'
} = {}) {
  const files = new Map()

  // Animation
  const timeline = new SVGTimeline({ duration: duration.get() + 's' })

  // Container
  const { width, height } = Store.document.format.get()
  const svg = createSVG(
    'svg',
    { width, height, viewBox: `0 0 ${width} ${height}` },
    document.body // Mounting so that animations can use to SVGElement.getBBox
  )

  // Render each <Page> template as SVG and append them to a SVG container
  let start = 0
  for (const [id, template] of Store.document.pages.get()) {
    // To allow using maths, all durations are expressed in frames
    const duration = timeline.toFrames(template.state.duration.get() + 's')
    if (!duration) continue

    // Render the template as a SVG
    const rendered = await template.toSVG({
      // <Folds> is a SVG-based renderer and should not be rasterized if animated
      rasterizeNestedSVG: false,
      splitText: true
    }, (element, svgElement) => {
      // Prepare relevant svgElement for animation
      const animation = getAncestorAttr(element, 'data-animation')
      if (animation && svgElement) {
        svgElement.id = UUID.animatable()
        svgElement.setAttribute('data-animation', animation)
        svgElement.setAttribute('data-animation-timing', getAncestorAttr(element, 'data-animation-timing'))
      }

      return destination === 'server'
        ? extractRasters(files)(element, svgElement)
        : svgElement
    })

    // Extract the template SVG and put it in the main SVG container as a <g>
    const g = createSVG('g', { id })
    g.appendChild(rendered)
    svg.appendChild(g)

    // Set the template visibility
    timeline.interpolations['#' + id] = {
      visible: {
        from: start,
        to: start + duration + timeline.toFrames(Data.animation.transition?.overlap)
      }
    }

    // Add optional page transition
    if (start > 0 && Data.animation.transition?.interpolations) {
      for (const attr in Data.animation.transition.interpolations) {
        timeline.interpolations['#' + id][attr] = {
          ...Data.animation.transition.interpolations[attr],
          delay: start
        }
      }
    }

    // Animate elements
    for (const svgElement of rendered.querySelectorAll('[data-animation]')) {
      const animation = Animations[svgElement.dataset.animation]
      if (!animation) {
        console.warn(`No ${animation} animation found`)
        continue
      }

      timeline.interpolations = {
        ...timeline.interpolations,
        ...animation.interpolate(svgElement, {
          timeline,
          initialDelay: start,
          maxDuration: duration,
          timing: (JSON.parse(svgElement.dataset.animationTiming ?? 'null') ?? undefined)
        })
      }
    }

    // Animate foldables
    for (const foldable of template.page.refs.foldables ?? []) {
      foldable.refs.foldable?.animate(timeline.interpolations, {
        easing: Data.animation.easing,
        // Animation duration is always 2s max
        duration: Math.min(timeline.toFrames(Data.animation.duration), duration),
        delay: timeline.toFrames(Data.animation.delay) + start
      })
    }

    start += duration
  }

  // Avoid ID collisions
  if (destination === 'browser') {
    for (const key in timeline.interpolations) {
      if (key.includes('nocollide')) continue

      const id = (/#([a-zA-Z0-9|\-|_|.]+)/.exec(key) ?? [])[1]
      if (!id) continue
      const newID = id + '__nocollide-' + Date.now()

      // Rename references to the collided ID
      for (const attr of ['id', 'clip-path', 'style']) {
        for (const el of svg.querySelectorAll(`[${attr}*="${id}"]:not([${attr}*="nocollide"])`)) {
          el.setAttribute(attr, el.getAttribute(attr).replace(id, newID))
        }
      }

      // Rename interpolation key
      renameObjectKey(timeline.interpolations, key, key.replace(id, newID))
    }
  }

  return {
    svg,
    files: destination === 'server' ? files : null,
    timeline
  }
}

export async function preview () {
  const { svg, timeline } = await create({ destination: 'browser' })

  return {
    svg,
    timeline,
    tick: fc => timeline.apply(svg, fc % timeline.length)
  }
}
