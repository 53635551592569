import * as Transform from 'transformation-matrix'
import { clamp } from 'missing-math'

import UUID from '/data/uuid'

import Corner from '/abstractions/Corner'

import noop from '/utils/noop'
import cover from '/utils/geom-cover'
import pointsToPathData from '/utils/points-to-path-data'

export default class Foldable {
  constructor (element, {
    id = UUID.foldable(),
    folds = element.dataset.folds ?? '',
    corners = [] // [{ data, position }, …]
  } = {}) {
    this.id = id
    this.element = element
    this.corners = this.parse(folds)

    // Load previous state
    corners.forEach((props, index) => this.corners[index].update(props))
  }

  forEach (callback = noop) {
    this.corners.forEach(callback)
  }

  get folded () {
    return Boolean(this.corners.find(corner => corner.folded))
  }

  get polygon () {
    // Build clipping polygon, sorted by index so that polygon is always cw
    const polygon = []
    this.forEach(corner => {
      if (corner.crease) {
        polygon.push(corner.crease[0])
        polygon.push(corner.crease[1])
      } else {
        polygon.push(corner.origin)
      }
    })

    return polygon
  }

  get clipPath () {
    return pointsToPathData(this.polygon)
  }

  reset () {
    this.forEach(corner => corner.reset())
  }

  resize (width, height) {
    const first = this.width === undefined || this.height === undefined

    this.width = width
    this.height = height

    // TODO[next] move corner using its normalized crease when not first (better proportional movements)
    this.forEach(corner => {
      const normalizedPosition = first
        ? corner.props.position ?? corner.props.origin
        : corner.normalize(corner.position)

      // Update domain first to ensure toDomain is correct in the next update call
      corner.update({ domain: [width, height] })
      corner.update({ position: corner.toDomain(normalizedPosition) })
    })
  }

  solve () {
    // Relax the corner linked list
    this.forEach(corner => corner.update())

    // Solve corners constraints, last touched last so that it knows about neighbors constraints
    for (const corner of [...this.corners].sort((a, b) => b.timestamp - a.timestamp)) {
      corner.solve()
    }
  }

  update (index, callback = noop) {
    const corner = this.corners[index]
    const props = callback(corner)
    corner.update(props)
  }

  render (renderer) {
    renderer.state.clipPath.set(this.clipPath)

    this.forEach((corner, index) => {
      const state = renderer.state.corners[index]
      state.id.set(corner.id)
      state.folded.set(corner.folded)
      state.shadow.set(pointsToPathData(corner.shadow))
      state.polygon.set(pointsToPathData(corner.polygon))
      state.crease.set(corner.crease)

      switch (corner.texture.type) {
        case 'color': {
          state.color.set(corner.texture.props.value)
          state.href.set(null)
          state.imagePositionMatrix.set(null)
          state.imageCropMatrix.set(null)
          break
        }

        case 'image': {
          state.color.set('transparent')
          state.href.set(corner.texture.image.src)
          state.filename.set(corner.texture.image.filename)

          // Matrix matching the corner geometry
          state.imagePositionMatrix.set(Transform.toString(Transform.compose(
            Transform.translate(corner.position[0], corner.position[1]),
            Transform.rotate(corner.angle)
          )))

          // Matrix croping the texture inside the corner geometry

          const { width, height } = corner.texture.image
          const { scale } = cover([width, height], corner.domain)
          const zoom = corner.data.imageZoom ?? 1
          // Ensure image is always in bounds
          const offx = clamp(corner.data.imageOffsetX ?? 0, corner.domain[0] - (width * (scale * zoom)), 0)
          const offy = clamp(corner.data.imageOffsetY ?? 0, corner.domain[1] - (height * (scale * zoom)), 0)

          const center = (() => {
            switch (corner.index) {
              case 0: return [width / 2, width / 2]
              case 1: return [width / 2, height / 2]
              case 2: return [height / 2, height / 2]
              case 3: return [width / 2, height / 2]
            }
          })()

          state.imageCropMatrix.set(Transform.toString(Transform.compose(
            Transform.translate(offx, offy),
            Transform.scale(scale * zoom),
            Transform.rotate((Math.PI / 2) * (index - 1), center[0], center[1])
          )))

          break
        }
      }
    })
  }

  animate (interpolations, props = {}) {
    // Create clipPath interpolation
    interpolations[`#${this.id}-clipPath path`] = {
      d: {
        ...props,
        from: this.corners.reduce((acc, corner) => [...acc, corner.origin, corner.origin], []),
        to: this.polygon
      }
    }

    // Create corners interpolations
    this.forEach(corner => {
      interpolations[`#${corner.id} .corner-shadow`] = {
        d: {
          ...props,
          from: new Array(3).fill(corner.origin),
          to: corner.shadow
        }
      }

      interpolations[`#${corner.id} .corner-polygon`] = {
        d: {
          ...props,
          from: new Array(3).fill(corner.origin),
          to: corner.polygon
        }
      }

      interpolations[`#${corner.id} .corner-crease`] = {
        x1: {
          ...props,
          from: corner.origin[0],
          to: corner.crease[0][0]
        },
        y1: {
          ...props,
          from: corner.origin[1],
          to: corner.crease[0][1]
        },
        x2: {
          ...props,
          from: corner.origin[0],
          to: corner.crease[1][0]
        },
        y2: {
          ...props,
          from: corner.origin[1],
          to: corner.crease[1][1]
        }
      }

      interpolations[`#${corner.id} .corner-image-position-matrix`] = {
        translateX: {
          ...props,
          from: corner.origin[0],
          to: corner.polygon[1][0]
        },
        translateY: {
          ...props,
          from: corner.origin[1],
          to: corner.polygon[1][1]
        }
      }
    })

    return interpolations
  }

  /**
   * Corners are defined in a data-folds HTMLAttribute with the following syntax:
   * - [] → corner placed at logical origin
   * - 0 → disabled corner
   * - [1, 0.5] → corner placed at normalized position
   */

  parse (string) {
    const parts = string.split(/(\[.*?\]|[^\s])\s?/).filter(Boolean)

    if (parts.length !== 4) {
      throw new Error('Foldable element should have a data-folds describing four corners using either `[0~1, 0~1]`, `[]` or `0`')
    }

    // Instanciate corners
    const corners = []
    for (let index = 0; index < 4; index++) {
      const part = parts[index]
      corners.push(
        new Corner(index, {
          data: { disabled: part === '0' },
          position: (() => {
            if (part === '0') return null
            if (part === '[]') return null

            const [, i, j] = /\[(-?[\d.]+),\s?(-?[\d.]+)\]/.exec(part)
            return [+i, +j]
          })()
        })
      )
    }

    // Init linked list
    for (const corner of corners) {
      corner.update({
        previous: corners[(corner.index + 3) % 4],
        next: corners[(corner.index + 1) % 4]
      })
    }

    return corners
  }

  toString () {
    return this.corners.map(corner => corner.toString()).join(' ')
  }

  toJSON () {
    return {
      folds: this.toString(),
      corners: this.corners.map(corner => corner.toJSON())
    }
  }
}
