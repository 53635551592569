import { clamp } from 'missing-math'

import pointInPolygon from 'point-in-polygon'

import UUID from '/data/uuid'

import Texture from '/abstractions/Texture'

import angle2pts from '/utils/geom-angle-between-two-points'
import distance from '/utils/geom-distance'
import intersection from '/utils/geom-intersection'
import midpoint from '/utils/geom-midpoint'
import perpendicular from '/utils/geom-perpendicular'

/**
 * Points follow the [x, y] standard
 * All polygons are sorted clockwise
 */

export default class Corner {
  constructor (index, {
    id = UUID.corner(),

    // Props coordinates are expressed on [0-1] because domain is still unknown
    position = [0, 0],
    origin = originFromIndex(index),

    shadowDistance = 10,

    // Attach arbitrary data
    data = {}
  } = {}) {
    this.toDomain = this.toDomain.bind(this)

    this.index = index
    this.previous = null
    this.next = null

    this.data = data

    this.props = {
      id,
      position,
      origin,
      shadowDistance
    }

    this.position = [...origin]
    this.domain = [0, 1]

    this.texture = Texture.empty
  }

  get id () {
    return this.props.id
  }

  get folded () {
    return distance(this.position, this.origin) >= 1
  }

  get origin () {
    return this.toDomain(this.props.origin)
  }

  get angle () {
    return angle2pts(this.position, this.polygon[0])
  }

  get polygon () {
    return this.crease
      ? [this.crease[0], this.position, this.crease[1]]
      : [this.origin, this.origin, this.origin]
  }

  get shadow () {
    // Avoid tending to infinity when position is really close to origin
    if (distance(this.position, this.origin) < 1) {
      return [this.origin, this.origin, this.origin]
    }

    const extend = perpendicular([this.border[1], this.position]) ?? this.border[1]
    return [
      this.polygon[0],
      [
        this.position[0] + extend[1] * this.props.shadowDistance,
        this.position[1] - extend[0] * this.props.shadowDistance
      ],
      this.polygon[2]
    ]
  }

  // Find a point is inside
  contains (point = [0, 0]) {
    if (this.index === 2) console.log([this.crease[0], this.position, this.crease[1]].join('\n'))
    return pointInPolygon(point, [this.crease[0], this.position, this.crease[1]])
  }

  // Scale a normalized point to the current domain
  toDomain (point = [0, 0]) {
    return [
      point[0] * this.domain[0],
      point[1] * this.domain[1]
    ]
  }

  // Normalize a domain point
  normalize (point = [this.domain[0], this.domain[1]]) {
    return [
      point[0] / this.domain[0],
      point[1] / this.domain[1]
    ]
  }

  // Compute delta between target position and current position
  delta (target = this.position) {
    return [
      this.position[0] - target[0] ?? this.position[0],
      this.position[1] - target[1] ?? this.position[1]
    ]
  }

  // Set position to origin
  reset () {
    this.update({
      position: this.origin
    })
  }

  // Update any public component
  update ({
    data = this.data,
    texture = this.texture,
    previous = this.previous,
    next = this.next,
    position = this.position,
    domain = this.domain,
    timestamp = this.timestamp ?? Date.now()
  } = {}) {
    this.previous = previous
    this.next = next

    this.domain = domain
    this.position = position

    this.data = data
    this.texture = texture
    this.timestamp = timestamp

    // Constrain position to domain
    this.position[0] = clamp(this.position[0], 0, this.domain[0])
    this.position[1] = clamp(this.position[1], 0, this.domain[1])

    // Border represents the corner of the paper where the folds will be
    this.border = [this.previous.origin, this.origin, this.next.origin]

    // Crease represents the two points of the border where the corner folds
    this.crease = creaseFromPoint(this.position, this.border)
  }

  solve () {
    if (!this.crease) return

    // Update position to solve for linked list constraints
    switch (this.index) {
      case 0: {
        const x = this.next?.crease ? Math.min(this.next.crease[0][0], this.domain[0]) : this.domain[0]
        const y = this.previous?.crease ? Math.min(this.previous.crease[1][1], this.domain[1]) : this.domain[1]
        this.crease[0][1] = clamp(this.crease[0][1], 0, y)
        this.crease[1][0] = clamp(this.crease[1][0], 0, x)
        break
      }

      case 1: {
        const x = this.previous?.crease ? Math.min(this.domain[0] - this.previous.crease[1][0], this.domain[0]) : this.domain[0]
        const y = this.next?.crease ? Math.min(this.next.crease[0][1], this.domain[1]) : this.domain[1]
        this.crease[0][0] = clamp(this.crease[0][0], this.domain[0] - x, this.domain[0])
        this.crease[1][1] = clamp(this.crease[1][1], 0, y)
        break
      }

      case 2: {
        const x = this.next?.crease ? Math.min(this.domain[0] - this.next.crease[0][0], this.domain[0]) : this.domain[0]
        const y = this.previous?.crease ? Math.min(this.domain[1] - this.previous.crease[1][1], this.domain[1]) : this.domain[1]

        this.crease[0][1] = clamp(this.crease[0][1], this.domain[1] - y, this.domain[1])
        this.crease[1][0] = clamp(this.crease[1][0], this.domain[0] - x, this.domain[0])
        break
      }

      case 3: {
        const x = this.previous?.crease ? Math.min(this.previous.crease[1][0], this.domain[0]) : this.domain[0]
        const y = this.next?.crease ? Math.min(this.domain[1] - this.next.crease[0][1], this.domain[1]) : this.domain[1]
        this.crease[0][0] = clamp(this.crease[0][0], 0, x)
        this.crease[1][1] = clamp(this.crease[1][1], this.domain[1] - y, this.domain[1])
        break
      }
    }

    // Recompute position based on constrained crease
    this.position = pointFromCrease(this.crease, this.origin)
  }

  // Snap to origin
  snap (threshold = 0) {
    if (!distance) return
    if (distance(this.position, this.origin) < threshold) this.reset()
  }

  toString () {
    if (this.data.disabled) return '0'
    if (!this.folded) return '[]'

    const i = this.position[0] / this.domain[0]
    const j = this.position[1] / this.domain[1]
    return `[${i.toFixed(2)}, ${j.toFixed(2)}]`
  }

  toJSON () {
    return {
      string: this.toString(),
      position: this.position,
      data: this.data
    }
  }
}

function originFromIndex (index) {
  return [[0, 3].includes(index) ? 0 : 1, [0, 1].includes(index) ? 0 : 1]
}

function pointFromCrease (crease, origin) {
  const creaseNormal = perpendicular(crease) ?? origin
  const ray = [
    origin.map((v, i) => v - creaseNormal[i] * 10_000),
    origin.map((v, i) => v + creaseNormal[i] * 10_000)
  ]

  const midpoint = intersection(crease, ray) || origin
  const dist = distance(origin, midpoint)
  return origin.map((v, i) => v + creaseNormal[i] * dist * 2)
}

function creaseFromPoint (point, [previous, origin, next]) {
  const foldMidpoint = midpoint([origin, point])
  const midpointNormal = perpendicular([origin, point]) || foldMidpoint
  const normalRay = [
    foldMidpoint.map((v, i) => v - midpointNormal[i] * 10_000),
    foldMidpoint.map((v, i) => v + midpointNormal[i] * 10_000)
  ]

  return [
    intersection([previous, origin], normalRay) || origin,
    intersection([origin, next], normalRay) || origin
  ]
}
