import './Fold.scss'
import { Component } from '@tooooools/ui'
import { derived, writable } from '@tooooools/ui/state'

import noop from '/utils/noop'

import Context from '/components/Context'

/**
 * Renderer of the Foldable abstraction.
 * Updated using <Foldable>#render(<FoldRenderer>)
 */

export default class FoldRenderer extends Component {
  beforeRender () {
    this.state = {
      clipPath: writable(''),
      corners: []
    }

    for (let index = 0; index < 4; index++) {
      this.state.corners[index] = {
        id: writable(),
        folded: writable(false),
        shadow: writable(),
        polygon: writable(),
        crease: writable([[],[]]),
        color: writable(),
        href: writable(),
        filename: writable(),
        imagePositionMatrix: writable(),
        imageCropMatrix: writable(),
        imageWidth: writable(),
        imageHeight: writable()
      }
    }
  }

  template (props, state) {
    return (
      <Context name='part:back'>
        <svg
          id={props.id}
          class='fold-renderer'
          width='100%'
          height='100%'
        >
          <defs ref={this.ref('defs')}>
            <filter id='blur'>
              <feGaussianBlur in='SourceGraphic' stdDeviation='1' />
            </filter>

            <clipPath id={props.id + '-clipPath'}>
              <path store-d={state.clipPath} />
            </clipPath>
          </defs>

          {state.corners.map((corner, index) => (
            <g
              class='corner'
              data-index={index}
              store-id={corner.id}
              store-visibility={derived(corner.folded, f => f ? 'visible' : 'hidden')}
            >
              <defs>
                <clipPath store-id={derived(corner.id, id => `${id}-clipPath`)}>
                  <path
                    class='corner-polygon'
                    store-d={corner.polygon}
                  />
                </clipPath>
              </defs>

              <path
                class='corner-shadow'
                fill='black'
                opacity='0.13'
                filter='url(#blur)'
                store-d={corner.shadow}
              />

              <g
                store-visibility={derived(corner.href, href => href ? 'visible' : 'hidden')}
                store-clip-path={derived(corner.id, id => `url(#${id}-clipPath)`)}
              >
                <g
                  class='corner-image-position-matrix'
                  store-transform={corner.imagePositionMatrix}
                >
                  <image
                    class='corner-image'
                    ref={this.refArray('images')}
                    store-href={corner.href}
                    store-width={corner.imageWidth}
                    store-height={corner.imageHeight}
                    store-transform={corner.imageCropMatrix}
                    store-data-filename={corner.filename}
                  />
                </g>
              </g>

              <path
                class='corner-polygon'
                store-d={corner.polygon}
                store-visibility={derived(corner.href, href => href ? 'hidden' : 'visible')}
                store-fill={corner.color}
                store-stroke={corner.color}
              />

              <line
                /**
                 * This line fixes 2 things caused by the clip-path:
                 * - a visual glitch due to anti-aliasing
                 * - the active stroke not being the same width on the crease
                 */
                class='corner-crease'
                store-x1={derived(corner.crease, c => c[0][0])}
                store-y1={derived(corner.crease, c => c[0][1])}
                store-x2={derived(corner.crease, c => c[1][0])}
                store-y2={derived(corner.crease, c => c[1][1])}
                store-stroke={corner.color}
              />
            </g>
          ))}
        </svg>
      </Context>
    )
  }

  resize (width, height) {
    this.base.setAttribute('viewBox', `0 0 ${width} ${height}`)
  }

  update () {
    ;(this.props['event-update'] ?? noop)(null, this)
  }
}
