export const group = 'boucle'
export const value = 'rotate'
export const label = 'rotation'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)

  const { x, y, width, height } = svgElement.getBBox()

  return {
    ['#' + svgElement.id]: {
      transformOrigin: [x + width / 2, y + height / 2],
      rotate: {
        from: 0,
        to: 360,
        delay,
        duration,
        loop: true
      }
    }
  }
}
