import { uid } from 'uid'

export const uuid = (ns = '') => (id = uid()) => `${ns}__${id}`

export default {
  generate: uuid('uuid'),

  animatable: uuid('animatable'),

  canvas: uuid('canvas'),
  clipPath: uuid('clipPath'),
  corner: uuid('corner'),
  foldable: uuid('foldable'),
  page: uuid('page'),

  session: uuid('session')
}
