/* global __VERSION__ */
import { r, w } from '@tooooools/ui/state'

const Store = {
  env: {
    debug: r(new URLSearchParams(window.location.search).has('debug')),
    version: r(`${__VERSION__}-${import.meta.env.MODE}`.replace('-production', '')),
    production: r(import.meta.env.MODE === 'production'),
    endpoint: r((window.location.pathname ?? '/').replace(/(\w)\/$/, '$1'))
  },

  app: {
    saving: w(false),

    xray: w(false),
    viewportScale: w(1), // Set in <App>#handleResize
    pipPosition: w([0, 0]), // Set in <Preview>#handleMove
    pipZoom: w(false), // Set in <Preview>#handleZoom

    context: w('document'), // The current <Context> name
    preview: w(), // The current <Preview>, see /controllers/actions:preview
    block: w(), // The current edited <Block>, see /controllers/Block
    page: w() // The current <Page>, see /controllers/Page

    // history: w(new Set()) // TODO[history]
  },

  // See /controllers/Document#dump|load
  document: {
    lastTouched: w(Date.now()),
    format: w(),
    formats: w([]), // Custom formats added by the user
    pages: w(new Map()), // { page__id: Template }
    assets: w({}) // { images: new Map(), … }
  },

  raf: {
    fps: r(60),
    running: w(false),
    frameCount: w(0)
  },

  api: {
    url: r(import.meta.env.VITE_API_URL),
    pollingInterval: r(1000),
    pollingMaxAttempts: r(30),
    version: w(null),
    wss: {
      url: r(import.meta.env.VITE_API_WSS_URL),
      // See /controllers/Api#connect
      id: w(null),
      message: w()
    }
  }
}

window.Store = Store
export default Store
