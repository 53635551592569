import './Texture.scss'
import { Component } from '@tooooools/ui'
import { not, ensure, readable, writable } from '@tooooools/ui/state'

import Store from '/data/store'

import normalizeWheel from 'normalize-wheel'
import classnames from 'classnames'

import Handle from '/components/Handle'

import getScaledBoundingClientRect from '/utils/dom-get-scaled-bounding-client-rect'
import noop from '/utils/noop'

export default class TextureBlockComponent extends Component {
  beforeRender (props) {
    this.reset = this.reset.bind(this)
    this.update = this.update.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleWheel = this.handleWheel.bind(this)

    if (!props.texture) throw new Error('The <TextureBlock> component requires a texture prop')

    this.state = {
      type: readable('texture'),

      pan: ensure(writable)(props['store-pan'] ?? props.pan),
      zoom: ensure(writable)(props['store-zoom'] ?? props.zoom)
    }
  }

  template (props, state) {
    return (
      <div
        data-title='Glisser-déplacer pour déplacer l’image de texture, utiliser la molette pour zoomer, maj+défilement pour zoomer précisément'
        class={classnames('texture', props.class)}
        store-class-is-panable={state.pan}
        store-class-is-zoomable={state.zoom}
        event-dblclick={this.reset}
      >
        <canvas ref={this.ref('canvas')} />
        <Handle
          store-hidden={not(state.pan)}
          event-move={this.handleMove}
        />
      </div>
    )
  }

  afterMount () {
    this.base.addEventListener('wheel', this.handleWheel, { passive: true })
    this.props.texture.state.offset.subscribe(this.update)
    this.props.texture.state.scale.subscribe(this.update)
    this.props.texture.state.mirror.subscribe(this.update)
    this.update()
  }

  reset () {
    this.props.texture.state.offset.reset()
    this.props.texture.state.scale.reset()
    this.props.texture.state.mirror.reset()
  }

  reflow () {
    this.update()
  }

  update () {
    const texture = this.props.texture
    if (!texture) return

    const container = getScaledBoundingClientRect(this.base, Store.app.viewportScale.get())
    // Crop the texture based on offset+scale, updating its src
    const { offset, scale } = texture.paint(this.refs.canvas, {
      x: texture.state.offset.current[0],
      y: texture.state.offset.current[1],
      destinationWidth: container.width,
      destinationHeight: container.height,
      scale: texture.state.scale.get()
    })

    // Silently update offset and scale to ensure clamped values
    texture.state.offset.current = offset
    texture.state.scale.current = scale

    ;(this.props['event-update'] ?? noop)(null, this)
  }

  handleMove ([dx, dy]) {
    this.props.texture.state.offset.update(([x, y]) => [x - dx, y - dy], true)
  }

  // TODO[next] zoom to cursor position
  handleWheel (e) {
    const { pixelY } = normalizeWheel(e)

    // TODO improve UX
    const amt = e.shiftKey ? 0.002 : 0.02
    let factor = 1 / Math.exp(Math.sign(pixelY) * amt)

    // Handle pinch-zoom on macOs
    if (e.ctrlKey) factor /= 1

    this.props.texture.state.scale.update(v => v * factor)
  }

  beforeDestroy () {
    this.base.removeEventListener('wheel', this.handleWheel, { passive: true })
    this.props.texture?.state.offset.unsubscribe(this.update)
    this.props.texture?.state.scale.unsubscribe(this.update)
    this.props.texture?.state.mirror.unsubscribe(this.update)
  }
}
