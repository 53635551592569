export default (element, attr) => {
  let value

  while (element.parentNode && !value) {
    value = element.getAttribute(attr)
    element = element.parentNode
  }

  return value
}
