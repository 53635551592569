import './Select.scss'
import { Component } from '@tooooools/ui'
import { ensure, derived, writable } from '@tooooools/ui/state'

import classnames from 'classnames'

import Store from '/data/store'
import * as Icons from '/data/icons'

import {
  Modal,
  FileDropper,
  Input,
  Tabs
} from '@tooooools/ui/components'
import Thumbnail from '/components/Thumbnail'

import noop from '/utils/noop'
import groupBy from '/utils/array-group-by'

export default class SelectModalComponent extends Component {
  beforeRender (props) {
    this.handleSelect = this.handleSelect.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.handleAssets = this.handleAssets.bind(this)

    this.state = {
      value: ensure(writable)(props['store-value'] ?? props.value)
    }
  }

  template (props, state) {
    const groups = groupBy(props.children, 'group')

    const tabs = []
    const panels = []

    for (const label in groups) {
      tabs.push({
        label,
        icon: Icons[Icons.aliases[label] ?? label],
        selected: groups[label].find(v => v === state.value.get())
      })

      panels.push(
        Tabs.panel(
          groups[label].map(value => (
            <Thumbnail
              label={value.label}
              src={value.preview?.src ?? value.src}
              ratio={value.preview?.ratio}
              padding={value.preview?.padding}
              width={value.preview?.width}
              store-active={derived(state.value, v => v === value)}
              event-click={this.handleSelect(value)}
            />
          )),
          {
            class: 'select-modal__content'
          }
        )
      )
    }

    if (props.upload) {
      tabs.push({
        label: props.upload.label ?? 'importés',
        icon: props.upload.icon ?? Icons.load,
        selected: (() => {
          const assets = Store.document.assets.current[this.props.assets]
          if (!assets) return
          for (const [, src] of assets) {
            if (src === state.value.current?.src) return true
          }
        })()
      })

      panels.push(
        Tabs.panel((
          <div class='select-modal__upload'>
            <Input
              icon={Icons[props.upload.icon ?? 'upload']}
              type='file'
              title={props.upload.title ?? 'ajouter un fichier à la galerie'}
              label={props.upload.label ?? 'parcourir…'}
              accept={props.upload.accept ?? '*'}
              event-input={this.handleInput}
            />
          </div>
        ),
        {
          class: 'select-modal__content',
          ref: this.ref('uploads')
        })
      )
    }

    const selectedTabIndex = tabs.findIndex(t => t.selected)

    return (
      <Modal
        title={props.title}
        class={classnames('select-modal', props.class)}
        event-close={props['event-close']}
      >
        {props.upload && (
          <FileDropper
            icon={Icons[props.upload.icon ?? 'upload']}
            label={props.upload.title ?? 'ajouter un fichier à la galerie'}
            event-drop={this.handleDrop}
          />
        )}

        {
          tabs.length > 1
            ? (
              <Tabs
                tabs={tabs}
                ref={this.ref('tabs')}
                value={Math.max(0, selectedTabIndex)}
              >
                {panels}
              </Tabs>
              )
            : panels
        }
      </Modal>
    )
  }

  afterRender () {
    Store.document.assets.subscribe(this.handleAssets)
    this.handleAssets()
  }

  handleSelect (value) {
    return () => {
      this.state.value.set(value)
      this.destroy()
    }
  }

  handleInput (e, input) {
    this.#upload(input.state.value.get())
  }

  handleDrop (e, input) {
    this.refs.tabs.state.value.set(this.refs.tabs.props.tabs.length - 1)
    this.#upload(input.state.files.get())
  }

  #upload (filelist) {
    if (!filelist) return
    const key = this.props.assets

    Store.document.assets.update(assets => {
      if (!assets[key]) assets[key] = new Map()

      for (const file of filelist) {
        assets[key].set(file.name, URL.createObjectURL(file))
      }

      return assets
    }, true)
  }

  clear () {
    if (this.refs.thumbnails) {
      this.refs.thumbnails.forEach(tbn => tbn.destroy())
      this.refs.thumbnails.length = 0
    }
  }

  handleAssets () {
    const assets = Store.document.assets.get()[this.props.assets]
    if (!assets) return

    this.clear()
    for (const [filename, objectURL] of new Map(Array.from([...assets].reverse()))) {
      this.render((
        <Thumbnail
          src={objectURL}
          label={filename}
          ref={this.refArray('thumbnails')}
          store-active={derived(this.state.value, v => v?.src === objectURL)}
          event-click={this.handleSelect({
            filename,
            src: objectURL,
            assets: this.props.assets
          })}
        />
      ), this.refs.uploads)
    }
  }

  beforeDestroy () {
    Store.document.assets.unsubscribe(this.handleAssets)
    ;(this.props['event-select'] || noop)(this.state.value.get())
  }
}
