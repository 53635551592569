export const group = 'texte'
export const value = 'shift'
export const label = 'décalage'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  if (!svgElement.classList.contains('text-fragment')) return

  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const span = Math.floor((timing[1] - timing[0]) * maxDuration)
  const easing = 'quadInOut'

  const { height } = svgElement.getBBox()
  const duration = span / 2

  return {
    [`#${svgElement.id} path`]: {
      span,
      opacity: { from: 0, to: 1, delay, duration, easing },
      translateY: { from: -height * 0.5, to: 0, delay, duration, easing }
    }
  }
}
