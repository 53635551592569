import './Preview.scss'
import { Component } from '@tooooools/ui'
import { not, ensure, derived, writable } from '@tooooools/ui/state'
import { clamp } from 'missing-math'

import Store from '/data/store'
import * as Icons from '/data/icons'
import * as Raf from '/controllers/Raf'

import {
  Button,
  Backdrop,
  Range,
  Toolbar
} from '@tooooools/ui/components'
import Handle from '/components/Handle'

export default class PreviewModalComponent extends Component {
  beforeRender (props) {
    this.handleClose = this.handleClose.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handlePlayPause = this.handlePlayPause.bind(this)
    this.handleZoom = this.handleZoom.bind(this)
    this.handlePip = this.handlePip.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleReset = this.handleReset.bind(this)

    this.state = {
      pip: ensure(writable)(props['store-pip'], props.pip)
    }
  }

  template (props, state) {
    const [x, y] = Store.app.pipPosition.get()
    const length = (props.length ?? 0) - 1

    return (
      <Backdrop
        class={['preview-modal', {
          'is-pip': state.pip,
          'is-zoomed': Store.app.pipZoom
        }]}
        ref={this.ref('backdrop')}
        event-close={props['event-close']}
        data-x={x}
        data-y={y}
      >
        <Handle
          event-move={this.handleMove}
          event-dblclick={this.handleReset}
        />
        <Toolbar class='preview-modal__toolbar' compact>
          <Button
            store-value={Store.app.pipZoom}
            icon={derived(Store.app.pipZoom, z => z ? Icons.zoomOut : Icons.zoomIn)}
            store-hidden={not(state.pip)}
            event-click={Store.app.pipZoom.toggle}
          />
          <Button
            store-icon={derived(state.pip, pip => pip ? Icons.fullscreen : Icons.pip)}
            event-click={state.pip.toggle}
          />
          <Button
            icon={Icons.close}
            title='Fermer l’aperçu'
            class='preview-modal__btn--close'
            event-click={this.handleClose}
          />
        </Toolbar>

        <div class='preview-modal__svg'>
          {props.children}
        </div>

        <Toolbar class='preview-modal__timeline' compact>
          <Button
            store-icon={derived(Store.raf.running, r => r ? Icons.pause : Icons.play)}
            store-title={derived(Store.raf.running, r => r ? 'Mettre en pause l’animation' : 'Jouer l’animation')}
            event-click={this.handlePlayPause}
          />
          <Range
            min={0}
            max={length}
            class='preview-modal__input--time'
            store-value={derived(Store.raf.frameCount, fc => fc % length)}
            event-input={this.handleInput}
          />
        </Toolbar>
      </Backdrop>
    )
  }

  afterMount () {
    this.handleMove()
    this.state.pip.subscribe(this.handlePip)
  }

  handleClose () {
    this.refs.backdrop.close()
  }

  handleInput (e) {
    Raf.stop()
    Store.raf.frameCount.set(+e.target.value)
  }

  handlePlayPause (e) {
    if (Store.raf.running.get()) Raf.stop()
    else Raf.start()
  }

  handleZoom () {
    if (!this.state.pip.get()) return
    this.log('foo')
    this.base.dataset.zoom = !this.base.dataset.zoom
    this.handlePip()
  }

  handlePip () {
    this.handleMove()
  }

  handleMove ([dx, dy] = [0, 0]) {
    if (!this.state.pip.get()) return

    const { width, height } = this.base.getBoundingClientRect()
    const x = clamp(+(this.base.dataset.x ?? 0) + dx, -(window.innerWidth - width - 40), 0)
    const y = clamp(+(this.base.dataset.y ?? 0) + dy, -(window.innerHeight - height - 80), 0)

    this.base.dataset.x = x
    this.base.dataset.y = y
    this.base.style.setProperty('--block-x', Math.floor(x) + 'px')
    this.base.style.setProperty('--block-y', Math.floor(y) + 'px')

    Store.app.pipPosition.set([x, y, Boolean(this.base.dataset.zoom)])
  }

  handleReset () {
    if (!this.state.pip.get()) return

    this.base.dataset.x = 0
    this.base.dataset.y = 0
    delete this.base.dataset.zoom
    this.handleMove()
  }

  beforeDestroy () {
    this.refs.backdrop.close()
    this.state.pip.unsubscribe(this.handlePip)
  }
}
