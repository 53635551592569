import './Thumbnail.scss'
import { Component } from '@tooooools/ui'
import { ensure, writable } from '@tooooools/ui/state'

import { debounce } from 'throttle-debounce'
import classnames from 'classnames'

import Store from '/data/store'
import UUID from '/data/uuid'

export default class ThumbnailComponent extends Component {
  beforeRender (props) {
    this.update = this.update.bind(this)
    this.renderTemplate = this.renderTemplate.bind(this)
    this.renderTemplateDebounced = debounce(50, this.renderTemplate, { noLeading: true })

    this.state = {
      active: ensure(writable)(props['store-active'] ?? props.active),
      label: ensure(writable)(props['store-label'] ?? props.label),
      src: ensure(writable)(props['store-src'] ?? props.preview ?? props.src)
    }
  }

  template (props, state) {
    return (
      <figure
        title={props.title}
        class={classnames('thumbnail', { 'is-interactive': props['event-click'] })}
        store-class-is-active={state.active}
        event-click={props['event-click']}
        event-dblclick={props['event-dblclick']}
        style={`
          --ratio: ${props.preview?.ratio ?? 'auto'};
          --ratio-fit: ${props.preview?.ratio ? 'cover' : 'contain'};
        `}
      >
        <div ref={this.ref('media')} class='thumbnail__media'>
          <img loading='lazy' store-src={state.src} />
        </div>

        <figcaption
          ref={this.ref('figcaption')}
          store-title={state.label}
          store-innerHTML={state.label}
        />
      </figure>
    )
  }

  afterRender () {
    Store.app.context.subscribe(this.update)
    this.props.template?.state.lastTouched.subscribe(this.update)
    this.update()
  }

  update () {
    const hidden = this.base.offsetParent === null
    if (!hidden) this.renderTemplateDebounced()
  }

  async renderTemplate () {
    if (!this.props.template) return

    const svg = await this.props.template.toSVG({ rasterizeNestedSVG: false })

    // Ensure no ID collisions with rendered clipPath and its <Page> source
    for (const clipPath of svg.querySelectorAll('clipPath')) {
      const id = clipPath.id
      if (!id) continue
      clipPath.id = UUID.generate()

      for (const ref of svg.querySelectorAll(`[clip-path*='${id}']`)) {
        ref.setAttribute('clip-path', ref.getAttribute('clip-path').replace(id, clipPath.id))
      }

      for (const ref of svg.querySelectorAll(`[style*='${id}']`)) {
        ref.style.clipPath = ref.style.clipPath.replace(id, clipPath.id)
      }
    }

    this.refs.media.innerHTML = ''
    this.render(svg, this.refs.media)
  }

  beforeDestroy () {
    Store.app.context.unsubscribe(this.update)
  }
}
