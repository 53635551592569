/* global __NAME__ */

const NS = `${__NAME__}__`

export function get (key) {
  return window.localStorage.getItem(NS + key)
}

export function set (key, value) {
  return window.localStorage.setItem(NS + key, value)
}
