import Store from '/data/store'
import { derived, writable } from '@tooooools/ui/state'

import Template from '/abstractions/Template'
import Texture from '/abstractions/Texture'

import * as Actions from '/controllers/Actions'
import * as Block from '/controllers/Block'

let current

const SIGNALS = new Map()
const COMMANDS = {
  setTemplate: async data => {
    if (!current) return

    data = typeof data === 'function' ? await data() : data
    if (!data) return

    Store.document.pages.update(pages => {
      const template = current.props.template
      template.page?.destroy()
      pages.set(template.id, new Template({
        props: data,
        id: template.id,
        slots: template.slots,
        state: template.state
      }))
    }, true)
  },

  setTexture: async (part, data) => {
    if (!current) return

    const props = typeof data === 'function' ? await data() : data
    if (!props) return

    current.props.template.state.textures.update(textures => {
      textures[part] = new Texture({ props })
      return textures
    }, true)
  },

  setFontScale: v => current?.props.template.state.fontScale.set(v),
  setDuration: v => current?.props.template.state.duration.set(v),
  updateDuration: v => current?.props.template.state.duration.update(d => d + v),

  toggleTextureContrast: part => current?.props.template.state.textures.update(textures => {
    textures[part]?.state.contrast.update(contrast => contrast === 'black' ? 'white' : 'black')
    return textures
  }, true),

  lock: () => current?.props.template.state.locked.set(true),
  unlock: () => current?.props.template.state.locked.set(false),

  insert: async (type, part, sources) => {
    if (!current) return
    switch (type) {
      case 'text': {
        const vnode = current.insertText(null, { part })
        Store.app.context.set('object:text')
        vnode?.components[0]._collector.components[0]?.register()
        break
      }

      case 'button': {
        const vnode = current.insertButton(null, { part })
        Store.app.context.set('object:button')
        vnode?.components[0]._collector.components[0]?.register()
        break
      }

      case 'image': {
        sources = sources ?? await Actions.selectPicto()
        if (!sources) return

        const vnode = await current.insertImage(undefined, {
          part,
          sources,
          context: 'object:picto',
          'event-dblclick': Block.exec('setSources', Actions.selectPicto)
        })

        Store.app.context.set('object:image')
        vnode?.components[0]._collector.components[0]?.register()

        current.handleTextures()
        break
      }
    }
  }
}

// Re-bind all signals when <Page> instance changes
Store.app.page.subscribe(instance => {
  // Set new current page
  current = instance

  // Bind and update available signals
  for (const [key, signal] of SIGNALS) bind(key, signal)
})

function bind (key, signal) {
  if (!current) signal.set(null)
  else if (current.state[key]) {
    signal.set(current.state[key].get())
    current.state[key].subscribe(signal.handler)
  }
}

// Allow targeting a state signal from the current <Page>
// If a value is specified, create the signal and return a derivation testing value equality
export function state (key, value = undefined) {
  if (!SIGNALS.has(key)) {
    const value = current?.state[key]?.get()
    const signal = writable(value)
    signal.handler = v => signal.set(v)
    bind(key, signal)

    SIGNALS.set(key, signal)
  }

  return value !== undefined
    ? derived(SIGNALS.get(key), v => v === value)
    : SIGNALS.get(key)
}

// Exec a command on current <Page> instance
export function exec (command, ...args) {
  return async e => {
    e?.stopPropagation()

    // Exec command
    if (COMMANDS[command]) await COMMANDS[command](...args)
    else console.warn(`No command '${command}' found`)
  }
}
