import './Export.scss'
import { Component } from '@tooooools/ui'
import { not, derived, writable } from '@tooooools/ui/state'

import Store from '/data/store'
import * as Icons from '/data/icons'

import * as Analytics from '/controllers/Analytics'
import * as Export from '/controllers/Export'

import noop from '/utils/noop'

import {
  Button,
  Modal,
  Toolbar
} from '@tooooools/ui/components'

export default class ExportModalComponent extends Component {
  beforeRender () {
    this.handleProgress = this.handleProgress.bind(this)

    this.state = {
      log: writable(),
      exporting: writable(false)
    }
  }

  template (props, state) {
    return (
      <Modal
        store-title={derived(state.exporting, s => s ? 'Export en cours…' : 'Exporter')}
        class='export-modal'
        store-locked={state.exporting}
        event-close={() => {
          this.beforeDestroy()
          ;(props['event-close'] ?? noop)(null, this)
        }}
      >
        <div
          class='export-modal__progress'
          store-class-is-visible={state.exporting}
        >
          <label store-innerHTML={state.log} />
          <progress ref={this.ref('progress')} max='100' />
        </div>

        <Toolbar
          store-disabled={state.exporting}
          class='export-modal__toolbar--exports'
        >
          <Button
            label='PNG'
            icon={Icons.image}
            store-disabled={not(Store.api.version)}
            event-click={this.handleExport('png')}
          />
          <Button
            label='SVG'
            icon={Icons.vector}
            event-click={this.handleExport('svg')}
          />
          <Button
            label='PDF'
            icon={Icons.printer}
            store-disabled={not(Store.api.version)}
            event-click={this.handleExport('pdf')}
          />
          <Toolbar disabled={Store.document.format.get()?.unit !== 'px'}>
            <Button
              label='MP4'
              icon={Icons.video}
              store-disabled={not(Store.api.version)}
              event-click={this.handleExport('mp4')}
            />
            <Button
              label='GIF'
              icon={Icons.video}
              store-disabled={not(Store.api.version)}
              event-click={this.handleExport('gif')}
            />
          </Toolbar>
        </Toolbar>
      </Modal>
    )
  }

  afterRender () {
    Export.progress.subscribe(this.handleProgress)
  }

  handleExport (filetype) {
    return async e => {
      this.state.log.set('Lancement du rendu…')
      this.state.exporting.set(true)
      const previousOnbeforeunload = window.onbeforeunload
      window.onbeforeunload = () => 'Le document est en cours d’export, êtes-vous sûr de vouloir quitter ?'

      try {
        await Export[filetype]()
        await Analytics.send(filetype)
      } finally {
        this.state.exporting.set(false)
        this.refs.progress.value = 0
        this.state.log.set(null)
        window.onbeforeunload = previousOnbeforeunload
      }
    }
  }

  handleProgress () {
    const progress = Export.progress.get()
    if (!progress) {
      this.refs.progress.value = -1
      return
    }

    this.refs.progress.value = progress.percent
    this.state.log.set(progress.message)
  }

  beforeDestroy () {
    Export.progress.unsubscribe(this.handleProgress)
  }
}
