import { render } from '@tooooools/ui'

import * as Icons from '/data/icons'

import Toast from '/components/Toast'

import escape from '/utils/string-escape'

let lastToast

export function display (message, stack, {
  tone = 'log',
  icon = undefined,
  duration = undefined
} = {}) {
  if (stack) message += `\n\n<pre>${escape(stack)}</pre>`

  // Avoid spamming user with the same message
  if (lastToast && lastToast.mounted && lastToast.props.message === message) {
    window.clearTimeout(lastToast.refs.timer)
    lastToast.state.quantity.update(q => ++q)
    if (lastToast.props.duration) {
      lastToast.refs.timer = window.setTimeout(() => lastToast.destroy(), lastToast.props.duration)
    }
    return
  }

  lastToast = render((
    <Toast {...{ tone, duration, icon, message }} />
  ), Toast.container).components[0]
}

export const log = (message, stack, props) => display(message, stack, { tone: 'log', ...props })
export const warn = (message, stack, props) => display(message, stack, { tone: 'warn', icon: Icons.warning, ...props })
export const error = (message, stack, props) => display(message, stack, { tone: 'error', icon: Icons.error, ...props })
