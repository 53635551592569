export const group = 'boucle'
export const value = 'pulse'
export const label = 'pulsations'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)
  const easing = 'sineInOut'

  const { x, y, width, height } = svgElement.getBBox()

  return {
    ['#' + svgElement.id]: {
      transformOrigin: [x + width / 2, y + height / 2],
      scale: {
        from: 1,
        to: 1.1,
        delay,
        duration,
        easing,
        loop: true,
        pingpong: true
      }
    }
  }
}
