/* global fetch, Blob, XMLHttpRequest, FormData */
import WebSocket from 'reconnectingwebsocket'

import Data from '/data/static'
import Store from '/data/store'

import { warn, error } from '/controllers/Toast'

const api = endpoint => Store.api.url.get() + '/' + endpoint

// Polling abstraction which will try to ping the API server until either the
// version is returned or the max attempts is reached
const polling = {
  timer: null,
  attempts: 0,
  start: function () {
    this.stop()

    if (this.attempts >= Store.api.pollingMaxAttempts.get()) {
      warn('Impossible de contacter l’API : certaines fonctionnalités ne seront pas disponibles')
      return
    }

    this.timer = setTimeout(async () => {
      this.attempts++
      try {
        const ping = await fetch(api('version'), { method: 'GET' })
        const response = await ping.json()
        if (!response || !response.version) throw new Error('Ping malformed')

        Store.api.version.set(response.version)
      } catch {
        this.start()
      }
    }, Store.api.pollingInterval.get())
  },

  stop: function () {
    clearTimeout(this.timer)
  }
}

export const connect = () => new Promise(resolve => {
  Store.api.version.subscribeOnce(resolve)
  polling.start()

  const socket = new WebSocket(Store.api.wss.url.get())
  socket.onmessage = message => {
    try {
      const data = JSON.parse(message.data)
      if (data.event === 'handshake') Store.api.wss.id.set(data.message)
      else Store.api.wss.message.set(data)
    } catch (err) {
      error('Une erreur est survenue lors de la communication avec l’API', err.message)
      throw err
    }
  }
})

// Using XHR instead of the new fetch API to be able to track upload progress
export const call = (endpoint, body, {
  progress = undefined,
  method = 'POST'
} = {}) => new Promise((resolve, reject) => {
  const request = new XMLHttpRequest()
  request.responseType = 'json'

  // Update progress signal
  if (progress && progress.set) {
    request.upload.onprogress = e => {
      if (!e.lengthComputable) return
      progress.set({
        event: 'progress',
        percent: e.loaded / e.total,
        context: 'upload'
      }, true)
    }
  }

  // Handle response
  request.onreadystatechange = () => {
    if (request.readyState !== 4) return

    // Create a map for all headers
    const headers = new Map()
    for (const line of request.getAllResponseHeaders().trim().split(/[\r\n]+/)) {
      const [header, value] = line.split(/:\s/)
      headers.set(header, value)
    }

    if (request.response.error) {
      reject(new Error(`[${request.status}] ${request.statusText}\n${request.response.message}`))
    } else {
      resolve(request.response)
    }
  }

  // Send request
  request.open(method, api(endpoint))
  request.send(body)
})

export const png = async (svg, { filename, files = new Map() } = {}, progress) => {
  const body = new FormData()
  body.append('filename', filename)
  body.append('svg', svg)
  for (const [filename, blob] of files) {
    body.append(`files[${filename}]`, blob, filename)
  }

  return call('svg/png', body, { progress })
}

export const pdf = async (svg, { filename, files = new Map() } = {}, progress) => {
  const body = new FormData()
  body.append('mode', Data.api?.pdfMode ?? 'svg-to-pdf')
  body.append('filename', filename)
  body.append('svg', svg)
  for (const [filename, blob] of files) {
    body.append(`files[${filename}]`, blob, filename)
  }

  return call('svg/pdf', body, { progress })
}

export const video = async (svg, { filename, files = new Map(), timeline } = {}, progress) => {
  const body = new FormData()
  body.append('filename', filename)
  body.append('svg', svg)
  body.append('timeline', JSON.stringify(timeline))
  body.append('progressReport', JSON.stringify({
    websocketServerUrl: Store.api.wss.url.get(),
    websocketClientUid: Store.api.wss.id.get()
  }))

  for (const [filename, blob] of files) {
    body.append(`files[${filename}]`, blob, filename)
  }

  return call('svg/video', body, { progress })
}

export const mp4 = video
export const gif = video

export const log = async data => {
  if (!data) return

  const [, yyyy, mm] = /(\d+)-(\d+)-(\d+)/g.exec(new Date().toISOString())
  const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
  return call(`json/append?uid=${yyyy}${mm}`, blob, { method: 'PATCH' })
}
