import circle from 'iconoir/icons/circle.svg?raw'

export const color = fill => {
  const stroke = fill === '#FFFFFF' ? 'var(--ui-color-text-dim)' : null
  return circle.replace(/stroke=".*?"/gi, `stroke="${stroke}" fill="${fill}"`)
}

// App
export { default as debug } from 'iconoir/icons/code.svg?raw'
export { default as upload } from 'iconoir/icons/upload.svg?raw'
export { default as moveLeft } from 'iconoir/icons/nav-arrow-left.svg?raw'
export { default as moveRight } from 'iconoir/icons/nav-arrow-right.svg?raw'
export { default as help } from 'iconoir/icons/question-mark.svg?raw'
export { default as openPicker } from 'iconoir/icons/nav-arrow-right.svg?raw'
export { default as closePicker } from 'iconoir/icons/nav-arrow-left.svg?raw'
export { default as format } from 'iconoir/icons/frame-simple.svg?raw'
export { default as updateFormat } from 'iconoir/icons/frame-select.svg?raw'
export { default as grid } from 'iconoir/icons/view-grid.svg?raw'
export { default as close } from 'iconoir/icons/regular/xmark.svg?raw'
export { default as ok } from 'iconoir/icons/regular/check.svg?raw'
export { default as alert } from 'iconoir/icons/regular/message-alert.svg?raw'
export { default as warning } from 'iconoir/icons/regular/warning-triangle.svg?raw'
export { default as error } from 'iconoir/icons/solid/warning-circle.svg?raw'
export { default as dropdown } from 'iconoir/icons/nav-arrow-down.svg?raw'
export { default as pip } from 'iconoir/icons/corner-bottom-right.svg?raw'
export { default as fullscreen } from 'iconoir/icons/expand.svg?raw'

// Document
export { default as new } from 'iconoir/icons/empty-page.svg?raw'
export { default as addTemplate } from 'iconoir/icons/plus.svg?raw'
export { default as load } from 'iconoir/icons/folder.svg?raw'
export { default as save } from 'iconoir/icons/floppy-disk.svg?raw'
export { default as restore } from 'iconoir/icons/refresh.svg?raw'
export { default as export } from 'iconoir/icons/rocket.svg?raw'
export { default as video } from 'iconoir/icons/media-video.svg?raw'
export { default as duration } from 'iconoir/icons/timer.svg?raw'
export { default as xray } from 'iconoir/icons/area-search.svg?raw'

// Artboard
export { default as previewStatic } from 'iconoir/icons/eye.svg?raw'
export { default as zoom } from 'iconoir/icons/search.svg?raw'
export { default as zoomIn } from 'iconoir/icons/zoom-in.svg?raw'
export { default as zoomOut } from 'iconoir/icons/zoom-out.svg?raw'
export { default as play } from 'iconoir/icons/play.svg?raw'
export { default as pause } from 'iconoir/icons/pause.svg?raw'

// Object
export { default as minus } from 'iconoir/icons/minus.svg?raw'
export { default as plus } from 'iconoir/icons/plus.svg?raw'
export { default as clone } from 'iconoir/icons/copy.svg?raw'
export { default as delete } from 'iconoir/icons/trash.svg?raw'
export { default as lock } from 'iconoir/icons/lock.svg?raw'
export { default as unlock } from 'iconoir/icons/lock-slash.svg?raw'
export { default as animate } from 'iconoir/icons/keyframes.svg?raw'

// Image
export { default as image } from 'iconoir/icons/media-image.svg?raw'
export { default as addImage } from 'iconoir/icons/media-image-plus.svg?raw'
export { default as crop } from 'iconoir/icons/crop.svg?raw'
export { default as contrast } from 'iconoir/icons/brightness.svg?raw'
export { default as vector } from 'iconoir/icons/design-nib.svg?raw'

// Text
export { default as addText } from 'iconoir/icons/text-box.svg?raw'
export { default as addButton } from 'iconoir/icons/plus-square.svg?raw'
export { default as alignCenter } from 'iconoir/icons/align-center.svg?raw'
export { default as alignLeft } from 'iconoir/icons/align-left.svg?raw'
export { default as alignRight } from 'iconoir/icons/align-right.svg?raw'
export { default as bold } from 'iconoir/icons/bold.svg?raw'
export { default as edit } from 'iconoir/icons/edit.svg?raw'
export { default as fontSize } from 'iconoir/icons/text-size.svg?raw'
export { default as italic } from 'iconoir/icons/italic.svg?raw'
export { default as underline } from 'iconoir/icons/underline.svg?raw'

// Picto
export { default as picto } from 'iconoir/icons/emoji.svg?raw'
export { default as user } from 'iconoir/icons/walking.svg?raw'
export { default as texture } from 'iconoir/icons/flower.svg?raw'
export { default as internet } from 'iconoir/icons/internet.svg?raw'
export { default as printer } from 'iconoir/icons/printer.svg?raw'
export { default as template } from 'iconoir/icons/journal-page.svg?raw'

export const aliases = {
  /* eslint-disable quote-props */
  'réseaux sociaux': 'internet',
  'impression': 'printer',
  'personnalisé': 'load',
  'personnes': 'user',
  'textures': 'texture',

  'pictogrammes': 'picto',
  'haut de gamme': 'image',
  'entité': 'image',
  'appartenance': 'image',
  'autres marques': 'image'
}
