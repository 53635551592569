export const group = 'texte'
export const value = 'typewriter'
export const label = 'machine à écrire'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  if (!svgElement.classList.contains('text-fragment')) return

  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)

  return {
    [`#${svgElement.id} path`]: {
      span: duration - delay,
      opacity: {
        from: 0,
        to: 1,
        delay,
        duration: 0
      }
    }
  }
}
