import './Portal.scss'
import { Component } from '@tooooools/ui'

import Store from '/data/store'
import * as Data from '/data/static'

import Changelog from '/components/Modal/Changelog'

export default class PortalComponent extends Component {
  template () {
    return (
      <main class='portal'>
        {
          Array.from(Data.Routes).map(([route, data]) => {
            return (
              <a class='data' href={route}>
                <img class='data__logo' src={data.portal.logo} />
                <div class='data__images'>
                  {data.portal.images.map(src => <div class='data__image'><img src={src} /></div>)}
                </div>
                <div class='data__colors'>
                  {data.colors.map(({ value }) => <div style={`--background-color: ${value}`} />)}
                </div>
              </a>
            )
          })
        }
        <footer>
          <a
            class='version'
            store-text={Store.env.version}
            event-click={() => this.render(<Changelog />)}
          />
        </footer>
      </main>
    )
  }
}
