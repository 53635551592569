import './Handle.scss'
import { Component } from '@tooooools/ui'
import { ensure, writable } from '@tooooools/ui/state'

import Store from '/data/store'

import normalizeWheel from 'normalize-wheel'
import classnames from 'classnames'

import noop from '/utils/noop'

export default class HandleComponent extends Component {
  beforeRender (props) {
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleDblClick = this.handleDblClick.bind(this)
    this.handleWheel = this.handleWheel.bind(this)

    this.state = {
      active: writable(false),
      disabled: ensure(writable)(props['store-disabled'] ?? props.disabled),
      hidden: ensure(writable)(props['store-hidden'] ?? props.hidden)
    }
  }

  template (props, state) {
    // Allow using another element as the handle
    return props.hydrate ?? <div class={classnames('handle no-export', props.class)} />
  }

  afterRender () {
    this.base.title = this.props['data-title'] ?? this.props.title ?? ''
    this.base.addEventListener('mousedown', this.handleMouseDown)
    this.base.addEventListener('dblclick', this.handleDblClick)
    this.base.addEventListener('wheel', this.handleWheel, { passive: true })
    this.state.active.subscribe(v => this.base.classList.toggle('is-active', v))
    this.state.disabled.subscribe(v => this.base.classList.toggle('is-disabled', v))
    this.state.hidden.subscribe(v => this.base.classList.toggle('is-hidden', v))
  }

  translate ([dx, dy]) {
    this.base.dataset.x = +(this.base.dataset.x ?? 0) + dx
    this.base.dataset.y = +(this.base.dataset.y ?? 0) + dy
    this.base.style.setProperty('--x', this.base.dataset.x + 'px')
    this.base.style.setProperty('--y', this.base.dataset.y + 'px')
  }

  #getScaledPointerPosition (e) {
    const scale = Store.app.viewportScale.get()
    const x = e.pageX
    const y = e.pageY

    // TODO[grid]
    // if (this.props.grid) {
    //   const [container, resolution] = this.props.grid
    //   const containerOffset = [
    //     container.offsetLeft % resolution,
    //     container.offsetTop % resolution
    //   ]

    //   x = roundTo(x, resolution) - containerOffset[0]
    //   y = roundTo(y, resolution) - containerOffset[1]
    // }

    return [x / scale, y / scale]
  }

  handleMouseDown (e) {
    if (e.buttons !== 1) return // Only left click

    e.preventDefault()

    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('mouseup', this.handleMouseUp)
    window.addEventListener('mouseleave', this.handleMouseUp)

    this.startDragPosition = this.#getScaledPointerPosition(e)
  }

  handleMouseMove (e) {
    e.preventDefault()

    this.state.active.set(true)

    let [x, y] = this.#getScaledPointerPosition(e)
    const [px, py] = this.lastPosition ?? this.startDragPosition

    // Lock axis based on start drag position
    if (e.shiftKey) {
      const dir = Math.abs(this.startDragPosition[0] - x) - Math.abs(this.startDragPosition[1] - y)
      x = dir > 1 ? x : this.startDragPosition[0]
      y = dir < 1 ? y : this.startDragPosition[1]
    }

    this.lastPosition = [x, y]
    ;(this.props['event-move'] ?? noop)([x - px, y - py], this)
  }

  handleMouseUp (e) {
    this.beforeDestroy()
    this.lastPosition = null
    this.state.active.set(false)

    ;(this.props['event-release'] ?? noop)(e, this)
  }

  handleDblClick (e) {
    ;(this.props['event-dblclick'] ?? noop)(e, this)
  }

  handleWheel (e) {
    const { pixelY } = normalizeWheel(e)

    // TODO improve UX
    const amt = e.shiftKey ? 0.002 : 0.02
    let factor = 1 / Math.exp(Math.sign(pixelY) * amt)

    // Handle pinch-zoom on macOs
    if (e.ctrlKey) factor /= 1

    ;(this.props['event-wheel'] ?? noop)(factor, this)
  }

  beforeDestroy () {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
    window.removeEventListener('mouseleave', this.handleMouseUp)
  }
}
