import './Artboard.scss'
import { Component } from '@tooooools/ui'
import { ensure, writable, derived } from '@tooooools/ui/state'

import Store from '/data/store'
import * as Icons from '/data/Icons'

import * as Actions from '/controllers/Actions'

import { Button } from '@tooooools/ui/components'
import Page from '/components/Page'

export default class ArtboardComponent extends Component {
  beforeRender (props) {
    this.update = this.update.bind(this)

    this.refs.pages = new Map()

    this.state = {
      hidden: ensure(writable)(props['store-hidden'] ?? props.hidden),
      empty: derived(Store.document.pages, pages => !pages.size)
    }
  }

  template (props, state) {
    return (
      <section
        class='artboard'
        store-class-is-hidden={state.hidden}
        store-class-is-empty={state.empty}
      >
        {/* <Page /> will be appended here */}
        <div
          class='empty'
          event-click={Actions.addPage}
          ref={this.ref('empty')}
        >
          <Button
            class='btn--empty'
            tabindex={-1}
            icon={Icons.template}
            label='ajouter un gabarit'
          />
        </div>
      </section>
    )
  }

  afterMount () {
    Store.document.pages.subscribe(this.update)
    this.update()
  }

  update () {
    const pages = Store.document.pages.get()

    // Clear obsolete <Page> instances
    for (const [id, page] of this.refs.pages) {
      if (pages.has(id)) continue
      page.destroy()
      this.refs.pages.delete(id)
    }

    // Add new <Page> components
    for (const [id, template] of pages) {
      this.render((
        this.refs.pages.has(id)
          // Reinsert <Page> base to ensure correct order
          ? this.refs.pages.get(id).base
          : <Page
              ref={this.refMap(id, 'pages')}
              template={template}
            />
      ), { insertBefore: this.refs.empty })
    }

    // Handle rare race condition in which arboard is shifted to the left
    window.setTimeout(() => { this._parent.base.scrollLeft = 0 }, 200)
  }

  getBoundingClientRect () {
    // Flag templates as resizing to ensure correct boundingClientRect
    for (const [, page] of this.refs.pages) page.refs.template.classList.add('is-resizing')

    const rect = this.base.getBoundingClientRect()

    // Remove resizing flag
    window.requestAnimationFrame(() => {
      for (const [, page] of this.refs.pages) {
        page.refs.template?.classList.remove('is-resizing')
      }
    })

    return rect
  }

  beforeDestroy () {
    Store.document.pages.unsubscribe(this.update)
  }
}
