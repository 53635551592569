export const group = 'objet'
export const value = 'pop'
export const label = 'pop'

export function interpolate (svgElement, {
  timeline,
  initialDelay = 0,
  maxDuration = timeline.length,
  timing = [0, 1]
} = {}) {
  const delay = initialDelay + Math.floor(timing[0] * maxDuration)
  const duration = Math.floor((timing[1] - timing[0]) * maxDuration)
  const easing = duration < 60 ? 'backOut' : 'elasticOut'

  const { x, y, width, height } = svgElement.getBBox()

  return {
    ['#' + svgElement.id]: {
      transformOrigin: [x + width / 2, y + height / 2],
      opacity: { from: 0, to: 1, delay, duration, easing: 'expoOut' },
      scale: { from: 0.6, to: 1, delay, duration, easing }
    }
  }
}
